<div
  id="onboarding_container"
  [ngClass]="{ remove_padding: this.current_step === 0 }"
>
  <div id="right_half" *ngIf="this.current_step !== 0">
    <div id="logo">
      <img
        _ngcontent-ng-c3400613608=""
        src="./assets/img/mine_mangabey_blue_green.svg"
        alt=""
      />
      <div>
        <img src="./assets/icons/powered_by.png" />
        <img src="./assets/icons/mangabey.png" />
      </div>
    </div>
    <div id="feature_content">
      <div id="feature_name">
        <p>{{ this.steps[this.current_step - 1].feature }}</p>
      </div>
      <h1 id="feature_summary">
        {{ this.steps[this.current_step - 1].feature_summary }}
      </h1>
      <p id="feature_description">
        {{ this.steps[this.current_step - 1].feature_description }}
      </p>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: end;
      "
    >
      <div id="onboarding_buttons">
        <p-button class="next_button" (onClick)="this.moveToNextStep()">{{
          this.current_step === 3 ? "Begin Your Data Adventure" : "Next"
        }}</p-button>
        <a class="skip_button" *ngIf="this.current_step !== 3" (click)="this.skipOnboarding()" style="cursor: pointer;"
          >Skip Onboarding</a
        >
        <div *ngIf="this.current_step !== 0" id="dont_show_again">
          <p-checkbox [binary]="true" (onChange)="this.dont_show_again_selected(this.dont_show_again)" [ngModel]="this.dont_show_again" [disabled]="this.dont_show_again_is_disabled"/>
          <p>Don't Show Me Again</p>
        </div>
      </div>
    </div>
  </div>

  <div id="images" *ngIf="this.current_step !== 0">
    <img
      src="/assets/img/onboarding_steps/big/{{this.current_step}}.png"
      alt="margin-right: -200px;"
    />
    <img
      src="/assets/img/onboarding_steps/small/{{this.current_step}}.png"
      style="position: absolute; top: 340px; right: 430px; z-index: 1000"
      alt=""
    />
  </div>

  <div id="intro" *ngIf="this.current_step === 0">
    <div id="intro_top">
      <img src="./assets/img/onboarding_left.png" />
      <div id="intro_center">
        <div id="logo" style="margin-top: 40px; margin-bottom: 25px">
          <img
            _ngcontent-ng-c3400613608=""
            src="./assets/img/mine_mangabey_blue_green.svg"
            alt=""
          />
          <div>
            <img src="./assets/icons/powered_by.png" />
            <img src="./assets/icons/mangabey.png" />
          </div>
        </div>
        <h1 id="feature_summary" style="margin-bottom: 8px">Welcome to Mine</h1>
        <p>
          Navigate through data sources with ease and unleash the potential of
          your information. Let MINE revolutionize the way you interact with
          your data sources - it's time to dig deeper, work smarter, and elevate
          your data game.
        </p>
        <p-button class="start_button" (onClick)="this.moveToNextStep()"
          >Let's Start</p-button
        >
      </div>
      <img src="assets/img/onboarding_right.png" />
    </div>
    <div id="intro_bottom">
      <img src="./assets/img/first_screenshot.png" />
    </div>
  </div>
</div>
