import { Component } from "@angular/core";
import { stages } from "./stages";
import { SourceService } from "../core/services/source.service";
import * as moment from "moment";
import {
  stepState,
  stepsInformation,
} from "../shared/steps-component/stepsInformation";
import { convertUTCToLocal } from "../core/utils/dateTime";
import { reOrderSummary, steps_ordering } from "../core/models/helpers";
import { convertAllKPIsToIntegers } from "../quality-center-dashboard/quality-center-dashboard.component";

@Component({
  selector: "app-home-component",
  templateUrl: "./home-component.component.html",
  styleUrls: ["./home-component.component.scss"],
})
export class HomeComponentComponent {
  time_durations = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "This Week", value: "this_week" },
    { label: "This Month", value: "this_month" },
  ];

  table_data = [] as any[];

  stepsState: stepsInformation | undefined;

  initial_notifications = [
    {
      stage: stages.InActive,
      active: true,
    },
  ] as any[];

  notifications = [] as any[];

  notifications_time_filter = "today";
  dataUpdateMetrics = {} as any;
  data_delivery_kpi = {} as any;
  data_library_kpi = {} as any;

  last_completed_batch_id: string | undefined;

  constructor(private sourceService: SourceService) {}

  ngOnInit() {
    this.sourceService.getNotifications().subscribe((notifications) => {
      this.initial_notifications = notifications.map(
        (notification: any): any => ({
          active: !notification.resolved,
          originator: notification.originator,
          message: this.capitalizeFirstLetter(notification.notification),
          date: convertUTCToLocal(
            moment(notification.notification_time).toDate()
          ),
          display_date: this.formatDate(
            convertUTCToLocal(moment(notification.notification_time).toDate())
          ),
          batch_id: notification.batch_id,
          user_id: notification.user_id,
          user_full_name: notification.user_full_name,
          user_profile_picture: notification.user_profile_picture,
          raw_data: notification.raw_data,
          step: notification.raw_data ? notification.raw_data.step : undefined,
          stage: notification.status,
        })
      );
      console.log(this.initial_notifications);
      this.notifications = this.initial_notifications;
      //this.notificationFilterChanged();
    });

    this.sourceService.getDataUpdateDashboardData().subscribe((res) => {
      const data = {} as Record<string, string>;
      res.forEach((kpi: any) => {
        data[kpi.status] = kpi.sources;
      });

      this.dataUpdateMetrics = data;
    });

    this.sourceService.getDataDeliveryDashboardData().subscribe((res) => {
      this.data_delivery_kpi = res;
    });

    this.sourceService.getDataLibraryDashboardData().subscribe((res) => {
      this.data_library_kpi = res;
    });

    this.sourceService.getHomeSummary().subscribe((res) => {
      if (res.length > 0) {
        this.last_completed_batch_id = res[0].batch_id;
      }

      res = convertAllKPIsToIntegers(res);
      this.table_data = reOrderSummary(res);
    });

    this.sourceService.getStatusSummary().subscribe((res) => {
      res = res[0];

      const duration = 50;

      this.stepsState = {
        collection: {
          duration: duration,
          state: stepState.completed,
        },
        cleaning: {
          duration: duration,
          state: stepState.completed,
        },
        consolidation: {
          duration: duration,
          state: stepState.completed,
        },
        referencing: {
          duration: duration,
          state: stepState.completed,
        },
        recoding: {
          duration: duration,
          state: stepState.completed,
        },
        categorization: {
          duration: duration,
          state: stepState.completed,
        },
        transcoding: {
          duration: duration,
          state: stepState.completed,
        },
      };
    });
  }

  fix_step_name(step: string) {
    if (step === "categorization") return "categorizing";
    else if (step === "transcoding_pg") return "transcoding";
    else return step;
  }

  capitalize(string: string) {
    if (string === undefined) {
      return "";
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  notificationFilterChanged() {
    const today = new Date();

    let date_filter = null;
    switch (this.notifications_time_filter) {
      case "today":
        date_filter = (notification: any) =>
          moment(today).isSame(notification.date, "day");
        break;
      case "yesterday":
        date_filter = (notification: any) =>
          this.isYesterday(notification.date);
        break;
      case "this_week":
        date_filter = (notification: any) =>
          moment(today).isSame(notification.date, "week");
        break;
      case "this_month":
        date_filter = (notification: any) =>
          moment(today).isSame(notification.date, "month");
        break;
    }

    return;
    if (date_filter) {
      this.notifications = this.initial_notifications.filter(date_filter);
      console.log(this.notifications);
    }
  }

  isYesterday(dateToCheck: Date) {
    const yesterday = moment().subtract(1, "days").toDate();

    return (
      dateToCheck.getFullYear() === yesterday.getFullYear() &&
      dateToCheck.getMonth() === yesterday.getMonth() &&
      dateToCheck.getDate() === yesterday.getDate()
    );
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDate(date: Date) {
    return `${this.padNumber(date.getDate())}.${this.padNumber(
      date.getMonth() + 1
    )}.${date.getFullYear()} ${this.getHHMM(date)}`;
  }

  getHHMM(date: Date) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  padNumber(number: number) {
    return String(number).padStart(2, "0");
  }

  getStepColor(notification: any) {
    const stepToColor = {
      collection: "grey",
      cleaning: "grey",
      consolidation: "#f59c4a",
      referencing: "#f59c4a",
      recoding: "#f59c4a",
      transcoding: "green",
    } as Record<string, string>;

    if (notification.raw_data && notification.raw_data.step) {
      const step = notification.raw_data.step;
      return stepToColor[step];
    }

    return "";
  }

  getStageColor(stage: stages | undefined) {
    switch (stage) {
      case stages.Pending:
        return "#8D48FD";
      case stages.Completed:
        return "#35BA55";
      case stages.InActive:
        return "#8E9397";
      default:
        return "#F59C4A";
    }
  }

  getStageLabel(stage: stages) {
    switch (stage) {
      case stages.Pending:
        return "Pending";
      case stages.Collection:
        return "Collection";
      case stages.Cleaning:
        return "Cleaning";
      case stages.Consolidation:
        return "Consolidation";
      case stages.Recoding:
        return "Recoding";
      case stages.Categorizing:
        return "Categorizing";
      case stages.Transcoding:
        return "Transcoding";
      case stages.Completed:
        return "Completed";
      case stages.InActive:
        return "Inactive";
      default:
        return "";
    }
  }
  getStageIcon(stage: stages) {
    switch (stage) {
      case stages.Pending:
        return "pending.svg";
      case stages.Collection:
        return "collection.svg";
      case stages.Cleaning:
        return "cleaning.svg";
      case stages.Consolidation:
        return "consolidation.svg";
      case stages.Recoding:
        return "recording.svg";
      case stages.Referencing:
        return "referencing.svg";
      case stages.Categorizing:
        return "categorizing.svg";
      case stages.Transcoding:
        return "transcoding.svg";
      case stages.Completed:
        return "completed.svg";
      case stages.InActive:
        return "inactive.svg";
      default:
        return "";
    }
  }
}
