<div>
  <p-table
    *ngIf="!this.isEmpty()"
    [value]="this.getArrayData()"
    styleClass="p-datatable-gridlines"
    [ngClass]="{ 'min-size': !this.isEmpty() }"
    scrollHeight="{{ this.isEmpty() ? undefined : '300px' }}"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let header of this.headers">{{ header.name }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr
        (click)="internalRowClicked(item)"
        (mouseenter)="internalRowMouseEnter(item)"
        (mouseleave)="internalRowMouseLeave()"
      >
        <td *ngFor="let header of this.headers">{{ item[header.key] }}</td>
      </tr>
    </ng-template>
  </p-table>

  <p-treeTable
    *ngIf="this.treeData"
    [value]="this.treeData"
    [columns]="this.getColumns()"
    [scrollable]="true"
    styleClass="p-datatable-gridlines"
    [ngClass]="{ 'min-size': !this.isEmpty() }"
    scrollHeight="{{ this.isEmpty() ? undefined : '300px' }}"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowNode
      let-rowData="rowData"
      let-columns="columns"
    >
      <tr
        [ttRow]="rowNode"
        (click)="internalRowClicked(rowData)"
        [ngClass]="{
          selected_row:
            this.selectionPredicate && this.selectionPredicate(rowData)
        }"
      >
        <td
          *ngFor="let col of columns; let i = index"
          [ngClass]="{ group_label: rowData['id'] === undefined }"
        >
          <p-treeTableToggler
            [rowNode]="rowNode"
            *ngIf="i === 0 && !rowData['id']"
          ></p-treeTableToggler>
          <ng-container *ngIf="i === 0">
            {{ rowData["label"] }}
          </ng-container>
          <ng-container *ngIf="rowData['sum'] && col.field !== 'field_name'">
            {{ rowData["sum"][col.field] }}
          </ng-container>
          <ng-container
            *ngIf="rowData['tooltip'] && col.field === 'field_name'"
          >
            <img
              src="assets/icons/tooltip_icon.svg"
              style="padding-left: 6px"
              [pTooltip]="rowData['tooltip']"
            />
          </ng-container>
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-treeTable>

  <div class="no_data_message" *ngIf="this.isArrayData() && this.isEmpty()">
    <p>No Data Available.</p>
  </div>
</div>
