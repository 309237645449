<div class="dialog_without_header">
  <div id="header">
    <div>
      <h2>Repeat</h2>
      <h3>Select update date and frequency</h3>
    </div>
    <img src="assets/icons/exit.svg" class="exit_button" (click)="closeDialog()" />
  </div>
  <div id="message">
    <div id="wrapper">
      <div id="start_date">
          <label for="">Start</label>
        <div id="start_date_input">
          <p-calendar [(ngModel)]="repeatInformation.start_date" dateFormat="dd.mm.yy" #start_date></p-calendar>
          <img src="/assets/icons/calendar.svg" (click)="this.showStartDateCalendarOverlay($event)">
        </div>
      </div>
      <div id="repeat_panel">
        <label for="">Repeat every</label>
        <p-dropdown id="every_other" [options]="this.everyOtherOptions" [(ngModel)]="repeatInformation.every"></p-dropdown>
        <p-dropdown id="units" [options]="this.unitsOptions" [(ngModel)]="repeatInformation.unit"></p-dropdown>
      </div>
      <div id="week_days">
        <p
          *ngFor="let day of this.week_days; index as day_index"
          (click)="weekDayClicked(day_index)"
          [ngClass]="{ selected: this.repeatInformation.week_day === day_index}">
          {{ this.singleLetterDay(day) }}
        </p>
      </div>
      <div id="message">
        <p>{{ this.message() }}</p>
      </div>
      <div id="end_date">
        <p-calendar [(ngModel)]="this.repeatInformation.end_date" dateFormat="MM d, yy" placeholder="End date"></p-calendar>
        <a (click)="unSelectEndDate()" *ngIf="this.repeatInformation.end_date">Remove end date</a>
      </div>
    </div>
  </div>
  <div id="footer">
    <p-button class="white_button" (onClick)="closeDialog()">Cancel</p-button>
    <p-button id="confirm_button" (onClick)="submitData()">Save</p-button>
  </div>
</div>