<p-card (click)="goToSourceDetails()">
  <ng-template pTemplate="title">
    <div class="card_header">
      <p>
        {{ this.getSourceName() }}
      </p>
      <div (click)="this.toggleCollapse($event)">
        <img
          src="{{
            this.tags_expanded
              ? 'assets/icons/source_card_collapse.svg'
              : 'assets/icons/source_card_expand.svg'
          }}"
        />
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="subtitle">
    <div class="end_period">
      <img src="assets/icons/source_card/calendar.svg" />
      <p>End Period: {{ getFormattedEndPeriod() }}</p>
    </div>
  </ng-template>

  <div
    class="tags"
    [ngClass]="{ expanded: this.tags_expanded }"
  >
    <button class="country_icon">
      <img [src]="getCountryIcon()" />{{ getCountryName() }}
    </button>
    <button *ngFor="let tag of getTags()">{{ tag }}</button>
  </div>

  <div style="display: flex; align-items: center; gap: 4px" class="next_update">
    <img src="assets/icons/source_card/clock.svg" />
    <p>Next Update: {{ getFormattedNextUpdateDate() }}</p>
  </div>
  <div style="display: flex; align-items: center; gap: 4px" class="person">
    <img src="assets/icons/source_card/person.svg" />
    <p>
      {{ person_name }}
    </p>
  </div>

  <ng-template pTemplate="footer">
    <div>
      <span [ngClass]="getFooterColorClass()"></span>
      <img [src]="getCurrentSourceStateIcon()" />
      <span class="source_status">{{ getCurrentSourceStateCaption() }}</span>
    </div>
  </ng-template>
</p-card>
