export default interface RepeatInformation {
  start_date: Date;
  end_date?: Date;
  every: number;
  /*
    Possible unit values:
    * 0 => Days
    * 1 => Weeks
    * 2 => Months
    * 3 => Quarters
    * 4 => Years
  */
  unit: number;
  week_day: number;
}
export const week_days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export function repeatMessage(repeatDetails: RepeatInformation): string {
    return `Occurs every ${week_days[repeatDetails.week_day]}` + (repeatDetails.end_date ? " until":"");
}