<div>
  <p-multiSelect
    [options]="this.options"
    [optionLabel]="'label'"
    [optionValue]="'value'"
    class="borderless"
    [(ngModel)]="this.selected_value"
    (onChange)="this.inputChanged($event)"
    (onBlur)="this.blur()"
  >
    <ng-template let-country pTemplate="footer">
      <div class="py-2 px-3">
        <p>Other</p>
        <input pInputText class="other_input" (change)="this.otherChanged($event)" [(ngModel)]="this.other_value"/>
      </div>
    </ng-template>
  </p-multiSelect>
</div>
