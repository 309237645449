import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updatesDataTypeFilter'
})
export class updatesDataTypeFilterPipe implements PipeTransform {

  transform(updates: any [], selectedDataTypes: string []): any [] {
    if (selectedDataTypes.length === 1 && selectedDataTypes[0] === "all"){
      return updates;
    }

    return updates.filter((update) => (update.data.some((source: any) => (source.data_type && selectedDataTypes.includes(source.data_type)))));
  }

}
