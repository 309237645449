import { Source } from "../core/models/source";
import { SourceState } from "../shared/source-card/source-card.component";

export function isActivated (source: any){
    return (source.is_activated===true)
}

export function isDeactivated (source: any){
    return (source.is_activated==false)
}

export function isActivatedInLastMonth (source: Source){
    return (source.activated_in_last_month==true)
}

export function isDeactivatedInLastMonth (source: Source){
    return (source.deactivated_in_last_month)
}

export function isAwaitingDelivery (source: Source){
    return (source.is_awaiting===true)
}

export function isDelayed (source: Source){
    return (source.is_delayed===true)
}

export function isPending (source: any){
    return (source.state && source.state===SourceState.Pending)
}

export function isInProgress (source: any){
    return (source.state && source.state===SourceState.InProgress)
}

export function isCompleted (source: any){
    return (source.state && source.state===SourceState.Completed)
}