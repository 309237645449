import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updatesFrequencyFilter'
})
export class updatesFrequencyFilterPipe implements PipeTransform {

  transform(updates: any [], selectedFrequencies: string []): any [] {
    if (selectedFrequencies.length === 1 && selectedFrequencies[0] === "all"){
      return updates;
    }

    return updates.filter((update) => (update.data.some((source: any) => (source.update_frequency && selectedFrequencies.includes(source.update_frequency)))));
  }

}
