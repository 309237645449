<div id="dialog_wrapper">
  <p-confirmDialog key="new_source_dialog" (onHide)="reload()">
    <ng-template pTemplate="header">
      <div>
        <h2>Add New Data Source</h2>
        <h3>Configure your source settings</h3>
        <h3 class="error_message" *ngIf="!isValid && formSubmitted">
          Please fill all required fields
        </h3>
      </div>
      <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
      <data-source-form
        [formSubmitted]="formSubmitted"
        (sourceInformationChanged)="sourceInformationUpdated($event)"
        (validationStateChanged)="validationStateChanged($event)"
      ></data-source-form>
      <div style="margin: 24px">
        <supported-formats></supported-formats>
      </div>
      <div style="margin: 24px">
        <upload-control
          (filesChanged)="updateSelectedFile($event)"
        ></upload-control>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button class="white_button" (onClick)="closeDialog()">Cancel</p-button>
      <p-button id="confirm_button" (onClick)="submitData()">Save</p-button>
    </ng-template>
  </p-confirmDialog>
</div>
