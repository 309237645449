import { Component, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Source } from 'src/app/core/models/source';
import { SourceService } from 'src/app/core/services/source.service';

@Component({
  selector: 'refresh-data-source',
  templateUrl: './refresh-data-source.component.html',
  styleUrls: ['./refresh-data-source.component.scss']
})
export class RefreshDataSourceComponent {

    constructor (private confirmationService: ConfirmationService, private sourceService: SourceService){}

    @Input() currentSource: Source | null = null;
    selectedFile : File | null = null;
    endPeriod = new Date();

    updateSelectedFile (file: File | null){
      this.selectedFile = file;
    }

    submitDialog (){
      if (this.currentSource !== null) {
        const newSource = Object.assign({}, this.currentSource);

        newSource.end_period = `${this.endPeriod.getFullYear()}-${this.endPeriod.getMonth()+1}-${this.endPeriod.getDate()}T00:00`;
        newSource.data_upload = this.selectedFile ?? undefined;
        this.sourceService.updateSource(newSource).subscribe(() => (true));
      }
    }

    closeDialog (){
      this.confirmationService.close();
    }
}
