import { brmFilterCriteria } from "../columns";

export function dataFilter(
  rows: any[],
  criteria: Record<string, brmFilterCriteria>
): any[] {
  console.log("FILTER CALLED", criteria);
  const columns = Object.keys(criteria);
  const predicates: Record<string, any> = {};

  columns.map(
    (column: string) =>
      (predicates[column] = { predicate: criteria[column].getPredicate() })
  );
  console.log(columns);
  console.log(predicates);

  const final_rows = rows.filter((row: any) => applyFilters(row, predicates));
  console.log(final_rows);
  return final_rows;
}

function applyFilters(
  row: any,
  filters: Record<string, { predicate: (value: any) => boolean }>
) {
  const columns = Object.keys(filters);
  console.log(columns);
  const values = columns.map((column: string) => {
    console.log(row[column]);
    const predicate = filters[column].predicate;
    console.log(
      "the predicate is ",
      predicate,
      "and result on ",
      row[column],
      "is = ",
      predicate(row[column])
    );
    return predicate(row[column]);
  });
  console.log(values.every((value: boolean) => value));
  return values.every((value: boolean) => value);
}
