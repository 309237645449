export enum stepState {
    pending,
    in_progress,
    completed
}

export interface stepInformation {
    duration: number; // in seconds
    state: stepState;
}

export interface stepsInformation {
    collection: stepInformation,
    cleaning: stepInformation,
    consolidation: stepInformation,
    referencing: stepInformation,
    recoding: stepInformation,
    categorization: stepInformation,
    transcoding: stepInformation
}