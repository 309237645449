export enum stages {
    Pending,
    Collection,
    Cleaning,
    Consolidation,
    Recoding,
    Referencing,
    Categorizing,
    Transcoding,
    Completed,
    InActive
}

export interface stringStageMapping {
  [key: string]: stages;
}
