import { TreeNode } from "primeng/api";

export interface dataGroup {
  groupName: string;
  data: any[] | dataGroup;
}

export interface Column {
  field: string;
  header: string;
}

export function isDataGroup(object: any): boolean {
  return object.groupName !== undefined && object.data !== undefined;
}

function sum(array: any[]) {
  let sum = 0;
  for (const number of array) {
    sum += number;
  }

  return sum;
}

export function wrapArrayInTreeNode(
  array: any,
  groupName: string,
  addSum = false,
  tooltip: string | null
): TreeNode<any>[] {
  let result = [
    {
      data: {
        label: groupName,
        tooltip: tooltip
      },
      children: array.map((item: any) => ({ data: item })),
      expanded: true
    },
  ] as Record<string, any>[];

  if (addSum) {
    let columns = [
      "current_records",
      "new_records",
      "percent_blanks",
      "percent_new_blanks",
      "unique_new_values",
      "unique_values",
    ] as any[];
    let final = {} as Record<string, any>;
    columns.map(
      (column: string) =>
        (final[column] = sum(array.map((item: any) => item[column])))
    );
    result[0]["data"]["sum"] = final;
  }
  console.log(result);

  return result;
}

export function wrapArrayInFlatTreeNode(
  array: any,
  groupName: string,
  addSum: boolean
): TreeNode<any> {
  const expanded = true;
  const result = {
    data: { label: groupName },
    children: array.map((item: any) => ({ data: item })),
    expanded: expanded,
  } as TreeNode<any>;

  if (addSum) {
    let columns = [
      "current_records",
      "new_records",
      "percent_blanks",
      "percent_new_blanks",
      "unique_new_values",
      "unique_values",
    ] as any[];
    let final = {} as Record<string, any>;
    columns.map(
      (column: string) =>
        (final[column] = sum(array.map((item: any) => item[column])))
    );
    result["data"]["sum"] = final;
  }

  return result;
}

export function wrapMultipleInTreeNode(
  groups: Record<string, any>,
  addSum: boolean
): TreeNode<any>[] {
  console.log("big function ", addSum);
  return Object.keys(groups).map((group_name: any) =>
    wrapArrayInFlatTreeNode(groups[group_name], group_name, addSum)
  );
}
