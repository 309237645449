import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NewListComponent } from "./new-list/new-list.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { SourceDetailComponent as SourceDetailsComponent } from "./source-details/source-details.component";
import { DashboardLayoutComponent } from "./dashboard-layout/dashboard-layout.component";
import { DataSourceFormComponent } from "./data-source-form/data-source-form.component";
import { PlaceHolderComponent } from "./place-holder/place-holder.component";
import { HomeComponentComponent } from "./home-component/home-component.component";
import { UpdateComponent } from "./update/update.component";
import { QualityCenterDashboardComponent } from "./quality-center-dashboard/quality-center-dashboard.component";
import { BRMComponent } from "./brm/brm.component";
import { VisibleColumnsComponent } from "./visible-columns/visible-columns.component";
import { SettingsComponent } from "./settings/settings.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { BrmActivityLogComponent } from "./brm-activity-log/brm-activity-log.component";
import { PresetsEditorComponent } from "./presets-editor/presets-editor.component";
import { MineDateTimeEditorComponent } from "./mine-date-time-editor/mine-date-time-editor.component";

const routes: Routes = [
  {
    path: "presets",
    component: MineDateTimeEditorComponent,
  },
  { path: "onboarding", component: OnboardingComponent },
  {
    path: "",
    component: DashboardLayoutComponent,
    children: [
      {
        path: "source_details/:source_id",
        canActivate: [AuthGuard],
        component: SourceDetailsComponent,
        data: { name: "source_details" },
      },
      {
        path: "home",
        canActivate: [AuthGuard],
        component: HomeComponentComponent,
        data: { name: "home" },
      },
      {
        path: "updates/all",
        canActivate: [AuthGuard],
        component: UpdateComponent,
        data: { name: "all_updates", status: "all" },
      },
      {
        path: "updates/pending",
        canActivate: [AuthGuard],
        component: UpdateComponent,
        data: { name: "pending_updates", status: "pending" },
      },
      {
        path: "updates/in_progress",
        canActivate: [AuthGuard],
        component: UpdateComponent,
        data: { name: "in_progress_updates", status: "in_progress" },
      },
      {
        path: "updates/completed",
        canActivate: [AuthGuard],
        component: UpdateComponent,
        data: { name: "completed_updates", status: "completed" },
      },
      {
        path: "quality_center_dashboard",
        canActivate: [AuthGuard],
        component: QualityCenterDashboardComponent,
        data: { name: "quality_center_dashboard", status: "completed" },
      },
      {
        path: "business_data",
        canActivate: [AuthGuard],
        component: BRMComponent,
        data: { name: "business_data" },
      },
      {
        path: "business_rules",
        canActivate: [AuthGuard],
        component: BRMComponent,
        data: { name: "business_rules" },
      },
      {
        path: "system_data",
        canActivate: [AuthGuard],
        component: BRMComponent,
        data: { name: "system_data" },
      },
      {
        path: "settings",
        canActivate: [AuthGuard],
        component: SettingsComponent,
        data: { name: "settings" },
      },
      {
        path: "placeholder",
        canActivate: [AuthGuard],
        component: PlaceHolderComponent,
        data: { name: "place_holder" },
      },
      {
        path: ":default_filter",
        canActivate: [AuthGuard],
        component: NewListComponent,
        data: { name: "all_sources" },
      },
      {
        path: "",
        canActivate: [AuthGuard],
        component: NewListComponent,
        data: { name: "all_sources" },
      },
    ],
  },
  {
    path: "data_source",
    component: DataSourceFormComponent,
  },
  {
    path: "landing",
    loadChildren: () =>
      import(`./landing/landing.module`).then((m) => m.LandingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
