import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import {
  channel_list,
  country_list,
  currencies,
  data_type_list,
  formulation_class_types,
  granularity_list,
  panel_list,
  price_levels,
  data_vendor_list,
  scopes_of_subscription,
  therapy_class_types,
  update_frequency_list,
  volume_units,
  update_frequency_units,
  data_usage_list
} from "../landing/my/filters-data";
import { Source } from "../core/models/source";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { RepeatDialogComponent } from "../repeat-dialog/repeat-dialog.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import RepeatInformation, { repeatMessage } from "../repeat-dialog/repeat";

@Component({
  selector: "data-source-form",
  templateUrl: "./data-source-form.component.html",
  styleUrls: ["./data-source-form.component.scss"],
  providers: [DialogService]
})
export class DataSourceFormComponent {
  countries: any[] = country_list;
  empty: any[] = [];
  @Input() source: Source = {};
  @Input() formSubmitted = false;

  @Output() sourceInformationChanged = new EventEmitter<Source>();
  @Output() validationStateChanged = new EventEmitter<boolean>(false);

  @ViewChild('repeat_dialog') repeatDialog: RepeatDialogComponent | null = null;

  data_providers = data_vendor_list;
  data_usage_list = data_usage_list;
  panel_groups = panel_list;
  data_type_list = data_type_list;
  granularities_list = granularity_list;
  channels_list = channel_list;
  volume_units = volume_units;
  currencies_list = currencies;
  update_frequency_list = update_frequency_list;
  scopes_of_subscription = scopes_of_subscription;
  formulation_class_types = formulation_class_types;
  therapy_class_types = therapy_class_types;
  price_levels = price_levels;

  end_period: Date = new Date();

  next_update: Date = new Date();
  current_update: Date = new Date();

  form: FormGroup = new FormGroup([]);

  repeatDetails: RepeatInformation | undefined = undefined;

  ref: DynamicDialogRef | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      country: new FormControl(this.source.country?.split(","), [Validators.required]),
      data_vendor: new FormControl(this.source.data_vendor, [
        Validators.required,
      ]),
      panel: new FormControl(this.source.panel, [Validators.required]),
      panel_group: new FormControl(this.source.panel_group),
      scope_of_subscription: [this.source.scope_of_subscription, Validators.required],
      data_type: [this.source.data_type, Validators.required],
      granularity: [this.source.granularity, Validators.required],
      channel: [this.source.channel, Validators.required],
      data_usage: [this.source.data_usage, Validators.required],
      panel_coverage: [isNaN(parseInt(this.source.panel_coverage ?? ""))? "" : parseInt((this.source.panel_coverage ?? ""))],
      formulation_class_type: new FormControl(
        this.source.formulation_class_type
      ),
      therapy_class_type: new FormControl(this.source.therapy_class_type),
      volume_units: this.source.volume_units === "" ? [] : new FormControl(this.source.volume_units?.split(",")),
      price_level: new FormControl(this.source.price_level),
      currency: new FormControl(this.source.currency),
      update_frequency: [this.source.update_frequency, Validators.required],
      start_date: new FormControl(
        this.source.start_date ? new Date(this.source.start_date) : ""
      ),
      end_period: new FormControl(
        this.source.end_period ? new Date(this.source.end_period) : "",
        [Validators.required]
      ),
      back_data_by_update: new FormControl(this.source.back_data_by_update),
      current_update: new FormControl(
        this.source.current_update ? new Date(this.source.current_update) : ""
      ),
      next_update: new FormControl(
        this.source.next_update ? new Date(this.source.next_update) : ""
      ),
      contact_owner_name: [this.source.contact_owner_name, Validators.required],
      contact_owner_email: [this.source.contact_owner_email, Validators.required]
    });

    this.form.statusChanges.subscribe((status) => {
      this.validationStateChanged.emit(status === "VALID");
    });

    this.form.valueChanges.subscribe((changes) => {
      if (this.form.valid){
        this.source = changes;
        this.sourceInformationChanged.emit(changes);
      }
    });

    //If it's not in edit mode, reset!
    if (this.source === null){
      this.form.reset();
      this.formSubmitted = false;
    }
  }

  getBackDataUnit() {

    if (this.form) {
      console.log(this.form.get("update_frequency")?.value);
      console.log(update_frequency_units[this.form.get("update_frequency")?.value]);
      return " "+update_frequency_units[this.form.get("update_frequency")?.value];
    }
    else
      return "";
  }

  openRepeatDialog() {
      this.ref = this.dialogService.open(RepeatDialogComponent, {});
  }

  deliveryCalendarMessage() {
    if (!this.repeatDetails)
      return "Set recurrence";
    else 
      return repeatMessage(this.repeatDetails);
  }
}
