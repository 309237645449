import { Component } from '@angular/core';

@Component({
  selector: 'app-mine-date-time-editor',
  templateUrl: './mine-date-time-editor.component.html',
  styleUrls: ['./mine-date-time-editor.component.scss']
})
export class MineDateTimeEditorComponent {

}
