import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Confirmation, ConfirmationService } from "primeng/api";
import RepeatInformation, { repeatMessage, week_days } from "./repeat";
import { Calendar } from "primeng/calendar";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-repeat-dialog",
  templateUrl: "./repeat-dialog.component.html",
  styleUrls: ["./repeat-dialog.component.scss"],
})
export class RepeatDialogComponent {
  week_days = week_days;
  everyOtherOptions = Array.from({ length: 7 }, (_, i) => i + 1);
  unitsOptions = ["Days", "Weeks", "Months", "Quarters", "Years"].map((unit, index) => ({label: unit, value: index}));

  @Input() repeatInformation: RepeatInformation = {
    start_date: new Date(),
    every: 1,
    week_day: 0,
    unit: 0
  };

  @Output() repeatInformationChanged = new EventEmitter<RepeatInformation>();

  @ViewChild('start_date') startDateCalendar: Calendar | undefined;
  currentDialogRef: DynamicDialogRef | undefined;
  
  constructor(private dynamicDialogRef: DynamicDialogRef) {}

  singleLetterDay(week_day: string) {
    return week_day.charAt(0);
  }

  weekDayClicked(week_day_index: number) {
    this.repeatInformation.week_day = week_day_index;
  }

  unSelectEndDate() {
    this.repeatInformation.end_date = undefined;
  }

  showStartDateCalendarOverlay(event: any) {
    if (this.startDateCalendar){
        this.startDateCalendar.showOverlay();
        this.startDateCalendar.cd.detectChanges();
    }
  }

  message() {
    return repeatMessage(this.repeatInformation);
  }

  submitData() {
    this.closeDialog();
  }

  closeDialog() {
    this.dynamicDialogRef.close();
  }
}
