import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { AccountService } from "./account.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  currentUser = undefined;
  constructor(
    private http: HttpClient,
    public router: Router,
    public accountService: AccountService
  ) {}

  setCurrentUser(user: any) {
    this.currentUser = user;
    localStorage.setItem("account_info", JSON.stringify(user));
  }

  getCurrentUser(): any {
    if (this.currentUser) return this.currentUser;

    const accountInfo = localStorage.getItem("account_info");
    if (accountInfo) {
      this.currentUser = JSON.parse(accountInfo);
    }

    return this.currentUser;
  }

  // Sign-up
  signUp(user: User): Observable<any> {
    let api = `${environment.apiUrl}/account/signup`;
    return this.http.post(api, user).pipe(catchError(this.handleError));
  }

  // Sign-in
  signIn(user: User) {
    let api = `${environment.apiUrl}/account/signin`;
    return this.http
      .post<any>(`${environment.apiUrl}/account/signin`, user)
      .pipe(catchError(this.handleError));
  }

  // Refresh account info
  refreshAccountInfo() {
    return this.accountService.getMyAccount();
  }

  getToken() {
    return localStorage.getItem("access_token");
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem("access_token");
    return authToken !== null ? true : false;
  }
  doLogout() {
    let removeToken = localStorage.removeItem("access_token");
    if (removeToken == null) {
      this.router.navigate(["signin"], { skipLocationChange: true });
    }
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg = {};
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error,
      };
    }
    return throwError(msg);
  }
}
