export const tablesByCategory: Record<string, any> = {
  business_data: {
    consolidation: {
      tables: [
        {
          displayName: "Product Table",
          backendName: "cso_product",
          mode: "view",
        },
      ],
    },
    referencing: {
      tables: [
        {
          displayName: "Molecules",
          backendName: "rec_molecules",
          mode: "edit",
        },
        {
          displayName: "Generic Status",
          backendName: "rec_gx",
          mode: "edit",
        },
        {
          displayName: "Prescription Status",
          backendName: "rec_rx",
          mode: "edit",
        },
        {
          displayName: "Channel Names",
          backendName: "rec_channel",
          mode: "edit",
        },
        {
          displayName: "Panel Names",
          backendName: "rec_panel",
          mode: "edit",
        },
        {
          displayName: "Panel Scope Categories",
          backendName: "cat_panel_scope",
          mode: "edit",
        },
        {
          displayName: "Formulation Class Type",
          backendName: "rec_nfc123",
          mode: "edit",
        },
        {
          displayName: "Therapy Class Type",
          backendName: "rec_atc4",
          mode: "edit",
        },
        {
          displayName: "Volume Units Factors",
          backendName: "rec_su",
          mode: "edit",
        },
      ],
    },
    categorization: {
      tables: [
        {
          displayName: "Transcoding Table",
          backendName: "trc_transcoding",
          mode: "view",
        },
      ],
    },
  },
  transcoding: {
    tables: [
      {
        displayName: "Product Grouping",
        backendName: "trc_pg",
        mode: "edit",
      },
      {
        displayName: "Split by Indication",
        backendName: "trc_sales_split",
        mode: "edit",
      },
      {
        displayName: "Days of Therapy",
        backendName: "trc_dot",
        mode: "edit",
      },
    ],
  },
  business_rules: {
    categorization: {
      tables: [
        {
          displayName: "Categorization Rules",
          backendName: "cat_category_rule",
          mode: "edit",
        },
        {
          displayName: "Categorization Hierarchy",
          backendName: "cat_category_hierarchy",
          mode: "edit",
        },
      ],
    },
  },
  system_data: {
    cleaning: {
      tables: [
        {
          displayName: "Encoding channel",
          backendName: "ecd_channel",
          mode: "view",
        },
        {
          displayName: "Encoding corporation",
          backendName: "ecd_corporation",
          mode: "view",
        },
        {
          displayName: "Encoding country",
          backendName: "ecd_country",
          mode: "view",
        },
        {
          displayName: "Encoding manufacturer",
          backendName: "ecd_manufacturer",
          mode: "view",
        },
        {
          displayName: "Encoding molecules",
          backendName: "ecd_molecules",
          mode: "view",
        },
        {
          displayName: "Encoding molecules_recode",
          backendName: "ecd_molecules_recode",
          mode: "view",
        },
        {
          displayName: "Encoding pack",
          backendName: "ecd_pack",
          mode: "view",
        },
        {
          displayName: "Encoding panel",
          backendName: "ecd_panel",
          mode: "view",
        },
        {
          displayName: "Encoding product",
          backendName: "ecd_product",
          mode: "view",
        },
        {
          displayName: "Encoding product_group1",
          backendName: "ecd_product_group1",
          mode: "view",
        },
        {
          displayName: "Encoding product_group2",
          backendName: "ecd_product_group2",
          mode: "view",
        },
        {
          displayName: "Encoding product_recode",
          backendName: "ecd_product_recode",
          mode: "view",
        },
        {
          displayName: "Encoding speciality",
          backendName: "ecd_speciality",
          mode: "view",
        },
        {
          displayName: "Encoding strength",
          backendName: "ecd_strength",
          mode: "view",
        },
      ],
    },
    presets: {
      tables: [
        {
          displayName: "Preview Function",
          backendName: "preview_function",
          mode: "edit"
        },
        {
          displayName: "Field Properties",
          backendName: "field_properties",
          mode: "edit"
        },
      ],
    },
  },
};
