import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-attributes-charts',
  templateUrl: './attributes-charts.component.html',
  styleUrls: ['./attributes-charts.component.scss']
})
export class AttributesChartsComponent {
  attributes_charts_options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
    scales: {
      x: {
        ticks: {
          padding: 14,
        },
        grid: {
          display: false,
          drawTicks: false,
          ticks: {
            color: "#8E9397",
            font: {
              size: 12,
              family: "Satoshi",
              weight: 500,
            },
          },
        },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 1500,
        border: {
          display: false,
        },
        ticks: {
          stepSize: 500,
          color: "#8E9397",
          crossAlign: "far",
          font: {
            size: 12,
            family: "Satoshi",
            weight: 500,
          },
        },
      },
    },
  };

  @Input() current_records_data = {};
  @Input() unique_values_data = {};
  @Input() percent_blanks_data = {};
  @Input() field_name = "Product";

}
