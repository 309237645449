<div id="dialog_wrapper">
  <p-confirmDialog key="edit_source_dialog">
    <ng-template pTemplate="header">
      <div>
        <h2>Source Settings</h2>
        <h3>Configure your source settings</h3>
      </div>
      <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
      <data-source-form
        #form
        (sourceInformationChanged)="sourceInformationUpdated($event)"
        [source]="this.getSourceCopy()"
      ></data-source-form>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button class="white_button" (onClick)="closeDialog()">Cancel</p-button>
      <p-button id="confirm_button" (onClick)="submitData()">Save</p-button>
    </ng-template>
  </p-confirmDialog>
</div>
