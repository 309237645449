import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { SourceService } from '../core/services/source.service';
import { Source } from '../core/models/source';
import { DataSourceFormComponent } from '../data-source-form/data-source-form.component';
import { convertUTCToLocal } from '../core/utils/dateTime';

@Component({
  selector: 'data-source-dialog',
  templateUrl: './data-source-dialog.component.html',
  styleUrls: ['./data-source-dialog.component.scss']
})
export class DataSourceDialogComponent {

  source: Source  | null = null;
  @ViewChild('form') form: DataSourceFormComponent | null = null;
  @Output() sourceUpdated = new EventEmitter();

  constructor(private confirmationDialog: ConfirmationService, private sourceSerivce: SourceService) {}

  sourceInformationUpdated (source: Source) {
    this.source = {id: this.source?.id, ...source};
    console.log(this.source?.end_period);
  }

  submitData() {
    if (this.source){
      this.source.data_upload = undefined;
      if (this.source.current_update)
        this.source.current_update = this.convertToDjangoFormat(new Date(this.source.current_update));
      else
        this.source.current_update = undefined;

      if (this.source.end_period)
        this.source.end_period = this.convertToDjangoFormat(new Date(this.source.end_period));

      if (this.source.next_update)
        this.source.next_update = this.convertToDjangoFormat(new Date(this.source.next_update));
      else
        this.source.next_update = undefined;

      if (this.source.start_date)
        this.source.start_date = this.convertToDjangoFormat(new Date(this.source.start_date));
      else
        this.source.start_date = undefined;

      this.sourceSerivce.updateSource(this.source).subscribe((res) => {
        this.sourceUpdated.emit();
        this.closeDialog();
      });
    }
  }

  closeDialog() {
    this.confirmationDialog.close();
  }

  convertToDjangoFormat (date: Date) {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}T00:00`;
  }

  getSourceCopy() {
    return { ...this.source};
  }
}
