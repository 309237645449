<div class="page_header">
  <div id="column_chooser_container" *ngIf="this.someRowsAreSelected()">
    <div id="batch_edit_column_chooser" #batch_container>
      <p (click)="this.openBatchEdit()">
        {{ this.numberOfSelectedRows() }} selected of
        {{ this.batchCount() }}
      </p>
      <p-dropdown
        [options]="this.editableHeaders"
        [(ngModel)]="this.selected_batch_edit_column"
        optionLabel="label"
        optionValue="value"
        (onChange)="batchEditColumnSelected()"
        placeholder="Select attribute to bulk edit"
        *ngIf="this.editingIsEnabled"
      />
      <div id="batch_approval_panel" *ngIf="this.approveChangesIsEnabled">
        <p class="reject_button" (click)="this.decideBatch('reject')">
          Reject Selected
        </p>
        <p class="accept_button" (click)="this.decideBatch('approve')">
          Approve Selected
        </p>
      </div>
    </div>
  </div>
  <div id="sub_menu" style="margin-bottom: 16px">
    <app-sub-menu></app-sub-menu>
  </div>
  <h1 class="screen_title">{{ this.getTitle() }}</h1>
  <app-confirm-batch-decision
    [decision_is_approval]="this.batch_decision_mode === 'approve'"
    [batchDecisionsIDs]="this.batchDecisionChangeIDs()"
  ></app-confirm-batch-decision>
  <app-confirm-changes-dialog
    [changeLog]="this.changeLog"
    [tableName]="this.selected_table"
    (windowClosed)="this.changesWindowClosed()"
    (changesConfirmed)="this.emptyChangeLog()"
  ></app-confirm-changes-dialog>
  <app-batch-edit-dialog
    *ngIf="this.selected_batch_edit_column"
    [id_list]="this.selected_rows_ids"
    [column]="this.selected_batch_edit_column"
    (newValueSubmitted)="this.newChange($event)"
  ></app-batch-edit-dialog>
  <div id="upper_bar">
    <div id="filters">
      <div id="filter_lists">
        <p-dropdown
          [options]="this.step_options"
          [(ngModel)]="this.selected_step"
          (onChange)="this.loadTables()"
          [group]="true"
        >
          <ng-template pTemplate="selectedItem">
            <div class="selected_step">
              <img
                src="assets/icons/steps/black/{{
                  this.getIconPath(selected_step)
                }}.svg"
              />
              <p>
                {{ this.currentStepLabel() }}
              </p>
            </div>
          </ng-template>
          <ng-template pTemplate="item" let-item>
            <div class="selected_step">
              <img
                src="assets/icons/steps/black/{{
                  this.getIconPath(item.value)
                }}.svg"
              />
              <p>
                {{ item.label }}
              </p>
            </div>
          </ng-template>
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="list_group_label">{{ group.label }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <p-dropdown
          [options]="this.table_options"
          [(ngModel)]="this.selected_table"
          *ngIf="this.selected_table"
          (onChange)="this.selectedTableChanged()"
          [group]="true"
        >
          <ng-template pTemplate="selectedItem">
            <div class="selected_step">
              <img
                src="assets/icons/{{ this.currentTableMode() }}_mode_brm.svg"
              />
              <p>
                {{ this.currentTableLabel() }}
              </p>
            </div>
          </ng-template>
          <ng-template pTemplate="item" let-item>
            <div class="selected_step">
              <img src="assets/icons/{{ item.mode }}_mode_brm.svg" />
              <p>
                {{ item.label }}
              </p>
            </div>
          </ng-template>
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="list_group_label">{{ group.label }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div id="right_side">
      <div
        class="brm_button clickable"
        (click)="this.enableApproveChangesMode()"
        *ngIf="
          this.currentTableMode() !== 'view' &&
          !this.approveChangesIsEnabled &&
          !this.editingIsEnabled &&
          this.userIsAtLeastManager()
        "
      >
        <img src="assets/icons/brm_approve_changes.svg" />
        <p>Approve Changes</p>
      </div>
      <div
        class="brm_button"
        (click)="enableEditing()"
        [ngClass]="{ clickable: this.currentTableMode() === 'edit' }"
        *ngIf="!this.editingIsEnabled && !this.approveChangesIsEnabled"
      >
        <img src="assets/icons/{{ this.currentTableMode() }}_mode_brm.svg" />
        <p *ngIf="this.currentTableMode() === 'view'">View Only</p>
        <p *ngIf="this.currentTableMode() === 'edit'">Edit Content</p>
      </div>
      <div
        class="brm_button clickable"
        (click)="op.toggle($event)"
        *ngIf="!this.editingIsEnabled"
      >
        <img src="assets/icons/brm_edit_columns.svg" />
        <p>Select Columns</p>
      </div>
      <div
        class="brm_button clickable"
        (click)="this.showActivityLog()"
        *ngIf="
          this.currentTableMode() !== 'view' &&
          !this.approveChangesIsEnabled &&
          !this.editingIsEnabled
        "
      >
        <img src="assets/icons/activity_log.png" />
        <p>Activity Log</p>
      </div>
      <p-overlayPanel #op>
        <div>
          <p-pickList
            [source]="this.headers"
            (onSourceReorder)="columnsReordered($event)"
            sourceHeader="Available"
            targetHeader="Selected"
            [dragdrop]="true"
          >
            <ng-template let-column pTemplate="item">
              <div class="column_reorder_item">
                <div>
                  <p-checkbox [(ngModel)]="column.visible" [binary]="true" />
                  {{ column.column.getHeader() }}
                </div>
                <img src="assets/icons/brm_reorder.svg" />
              </div>
            </ng-template>
          </p-pickList>
        </div>
      </p-overlayPanel>

      <ng-container>
        <div
          class="brm_button"
          (click)="this.undoLastChange()"
          *ngIf="
            this.changeLog.getItems().length > 0 &&
            this.editingIsEnabled &&
            !this.approveChangesIsEnabled
          "
          [ngClass]="{ clickable: this.currentTableMode() === 'edit' }"
        >
          <img src="/assets/icons/brm_undo.svg" />
          <p>Undo</p>
        </div>
        <div
          class="brm_button"
          (click)="cancelEditing()"
          *ngIf="this.editingIsEnabled || this.approveChangesIsEnabled"
          [ngClass]="{ clickable: this.currentTableMode() === 'edit' }"
        >
          <p>Cancel</p>
        </div>
        <div
          class="brm_button commit_changes"
          *ngIf="
            this.editingIsEnabled &&
            this.changeLog.getItems().length > 0 &&
            !this.approveChangesIsEnabled
          "
          (click)="openConfirmChanges()"
          [ngClass]="{ clickable: this.currentTableMode() === 'edit' }"
        >
          <p>Commit Changes</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div style="margin-bottom: 36px"></div>
<div id="table" [ngClass]="{ single_column: !this.show_activity_log }">
  <div id="data">
    <div id="formula">
      <img src="assets/icons/brm_cancel.svg" (click)="this.resetFormulaBar()" />
      <img
        src="assets/icons/brm_validate.svg"
        class="clickable"
        style="margin-right: 16px"
        (click)="this.submitFormulaBarChange()"
      />
      <input
        pInputText
        type="text"
        [value]="
          this.formula_bar_new_value
            ? this.formula_bar_new_value
            : this.getFormulaBarValue(this.changeLog)
        "
        [disabled]="!this.editingIsEnabled || !this.selectedColumnIsString()"
        (change)="this.formulaBarChange($event)"
      />
      <div
        class="brm_button add_row_button clickable"
        style="width: 100px"
        (click)="addRow()"
        *ngIf="
          this.editingIsEnabled &&
          (this.selected_table === 'cat_category_rule' ||
            this.selected_table === 'cat_category_hierarchy')
        "
      >
        <p>+ Add a row</p>
      </div>
    </div>
    <div style="margin-top: 40px">
      <p-table
        *ngIf="this.data"
        [paginator]="true"
        [rows]="50"
        [columns]="this.headers"
        [value]="
          this.data
            | rowFilter : this.filteringCriteria
            | sorterFilter : this.sorter
            | changesFilter : this.approveChangesIsEnabled : this.pendingChanges
        "
        [resizableColumns]="true"
        columnResizeMode="expand"
        #dt
        [scrollable]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of this.getVisibleHeaders()"
              style="width: {{ col.width }}px;"
              pResizableColumn
            >
              <div
                class="brm_header"
                [ngClass]="{
                  selection_column: col === this.getVisibleHeaders()[0]
                }"
              >
                <div
                  class="brm_header_text"
                  [ngClass]="{
                    selection_column: col === this.getVisibleHeaders()[0]
                  }"
                >
                  <ng-container *ngIf="col === this.getVisibleHeaders()[0]">
                    <p-checkbox
                      [binary]="true"
                      (onChange)="allRowSelectionChanged($event)"
                      [(ngModel)]="this.allSelection"
                      *ngIf="
                        (this.editingIsEnabled &&
                          this.currentTableMode() === 'edit') ||
                        this.approveChangesIsEnabled
                      "
                      [(ngModel)]="this.all_rows_selected"
                    ></p-checkbox>
                  </ng-container>
                  <p>{{ col.column.getHeader() }}</p>
                </div>
                <img
                  class="clickable"
                  src="assets/icons/filter_trigger_brm.svg"
                  (click)="op.toggle($event)"
                />
                <p-overlayPanel #op>
                  <app-brm-column-filter
                    [column]="col.column"
                    (filterChanged)="
                      this.filterChanged($event, col.column.field)
                    "
                    (sorterChanged)="this.sorterChanged($event, col.column)"
                    (filterReset)="this.filterReset($event)"
                  ></app-brm-column-filter>
                </p-overlayPanel>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rule>
          <tr>
            <td
              *ngFor="let header of this.getVisibleHeaders()"
              [ngClass]="{
                display_overflow: header.column.displayOverflow,
                selected:
                  this.approveChangesIsEnabled &&
                  this.columnIsChanged(rule, header.column.getField())
              }"
              (click)="
                this.approveChangesIsEnabled
                  ? this.displayCellHistory(
                      this.columnIsChanged(rule, header.column.getField()),
                      rule
                    )
                  : this.cellClicked(rule, header.column)
              "
            >
              <div
                class="cell_wrapper"
                [ngClass]="{
                  selection_column: header === this.getVisibleHeaders()[0],
                  left_align: this.isStringHeader(header)
                }"
              >
                <ng-container *ngIf="header === this.getVisibleHeaders()[0]">
                  <p-checkbox
                    [binary]="true"
                    [trueValue]="1"
                    [falseValue]="0"
                    (onChange)="this.rowSelectionChanged(rule, $event)"
                    [(ngModel)]="this.rowSelection[rule.selection_id]"
                    *ngIf="
                      (this.editingIsEnabled &&
                        this.currentTableMode() === 'edit') ||
                      this.approveChangesIsEnabled
                    "
                  ></p-checkbox>
                </ng-container>
                <app-brm-cell-editor
                  [column]="header.column"
                  [displayValue]="
                    rule
                      | applyChangesFilter : this.changeLog : this.primary_key
                  "
                  (newChange)="this.newChange($event)"
                  [editingState]="this.editingIsEnabled"
                  [primary_key]="this.primary_key"
                  [width]="header.width"
                >
                </app-brm-cell-editor>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div id="progress_container">
        <p-progressSpinner *ngIf="!this.data"></p-progressSpinner>
      </div>
    </div>
  </div>
  <app-brm-activity-log
    *ngIf="this.show_activity_log"
    (logClosed)="this.show_activity_log = false"
    (userAskedToGoToRow)="this.scrollToRow($event)"
    [activities]="this.activity_log"
    [selected_cell_key]="this.selected_cell_key"
    [userAtLeastManager]="this.userIsAtLeastManager()"
  ></app-brm-activity-log>
</div>
