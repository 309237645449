import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updatesChannelFilter'
})
export class updatesChannelFilterPipe implements PipeTransform {

  transform(updates: any [], selectedChannels: string []): any [] {
    console.log(selectedChannels)
    if (selectedChannels.length === 1 && selectedChannels[0] === "all"){
      return updates;
    }
    return updates.filter((update) => (update.data.some((source: any) => (source.channel && selectedChannels.includes(source.channel)))));
  }

}
