import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sourcesDataTypeFilter'
})
export class SourcesDataTypeFilterPipe implements PipeTransform {

  transform(sources: any [], selectedDataTypes: string []): any [] {
    if (selectedDataTypes.length === 1 && selectedDataTypes[0] === "all"){
      return sources
    }

    return sources.filter((source) => (source.data_type && selectedDataTypes.includes(source.data_type)));
  }

}
