import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sourcesChannelFilter'
})
export class SourcesChannelFilterPipe implements PipeTransform {

  transform(sources: any [], selectedChannels: string []): any [] {
    if (selectedChannels.length === 1 && selectedChannels[0] === "all"){
      return sources
    }
    return sources.filter((source) => (source.channel && selectedChannels.includes(source.channel)));
  }

}
