import { Injectable, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "src/environments/environment";
import { Observable, map, catchError, throwError, of } from "rxjs";
import { Source } from "../models/source";

@Injectable({ providedIn: "root" })
export class SourceService {
  currentSource: any = null;
  headers = new HttpHeaders().set("Content-Type", "application/json");

  sourceUpdated = new EventEmitter();

  constructor(private http: HttpClient) {}

  // Runs a GET request
  post(url: string): Observable<any> {
    return this.http.post(url, null);
  }

  // My Account
  getMySource(): Observable<any> {
    let api = `${environment.apiUrl}/source/my/`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res) => {
        return res || {};
      }),
      catchError(this.handleError)
    );
  }

  // Get source for ID
  getSourceById(id: string): Observable<any> {
    if (this.currentSource !== null && this.currentSource["id"] == id) {
      return of(this.currentSource);
    }

    let api = `${environment.apiUrl}/source/get/${id}`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res) => {
        return res || {};
      }),
      catchError(this.handleError)
    );
  }

  // Get attribute informations of source_name
  getAttributeInformation(source_name: string): Observable<any> {
    let api = `${environment.apiUrl}/source/kpi/${source_name}`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // Get attribute informations evolution
  getAttributeInformationEvolution(
    source_name: string,
    attribute: string
  ): Observable<any> {
    let api = `${environment.apiUrl}/source/attr_stats?source_name=${source_name}&attr=${attribute}`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // Get sales information
  getSalesInformation(source_name: string): Observable<any> {
    let api = `${environment.apiUrl}/source/saleskpi/${source_name}`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // Get delays data
  getDelays(source_name: string): Observable<any> {
    //@TODO: change count to correct number;
    const count = 10;

    let api = `${environment.apiUrl}/source/data_delivery_delay/${source_name}/${count}`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }
  // Get delays data for multiple sources
  getMultipleDelays(source_name: string[]): Observable<any> {
    //@TODO: change count to correct number;
    const count = 10;

    let api = `${
      environment.apiUrl
    }/source/data_delivery_delay/${source_name.join(",")}/${count}`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // Get data delivery data
  getDataDelivery(source_name: string): Observable<any> {
    //@TODO: change count to correct number;
    const count = 10;

    let api = `${environment.apiUrl}/source/data_delivery_history/${source_name}`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // Get QC stats
  getQCStats(): Observable<any> {
    let api = `${environment.apiUrl}/uc/stats`;
    return this.http.get<any>(api, { headers: this.headers });
  }

  // Get notifications data
  getNotifications(): Observable<any> {
    let api = `${environment.apiUrl}/notifications/all/`;
    return this.http
      .get<any>(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // Create a new prompt response
  createSource(ctx: Source): Observable<any> {
    let api = `${environment.apiUrl}/source/add/`;
    const formData = objectToFormData(ctx, true);
    return this.http.post(api, formData).pipe(catchError(this.handleError));
  }

  // Create a new prompt response
  updateSource(ctx: Source): Observable<any> {
    let api = `${environment.apiUrl}/source/update/`;
    console.log(ctx);
    const formData = objectToFormData(ctx, true);
    return this.http.post(api, formData).pipe(catchError(this.handleError));
  }

  // Delete prompt response
  deleteSource(ctxid: number): Observable<any> {
    let api = `${environment.apiUrl}/source/delete/`;
    return this.http
      .post(api, { sourceid: ctxid })
      .pipe(catchError(this.handleError));
  }

  // Re-activate source
  reactivateSource(source_id: string): Observable<any> {
    let api = `${environment.apiUrl}/source/api/reactivate-source/${source_id}/`;
    return this.http.post(api, {}).pipe(catchError(this.handleError));
  }

  // Deactivate source
  deactivateSource(source_id: string): Observable<any> {
    let api = `${environment.apiUrl}/source/api/deactivate-source/${source_id}/`;
    return this.http.post(api, {}).pipe(catchError(this.handleError));
  }

  // Delete prompt response
  getSourceFilters(): Observable<any> {
    let api = `${environment.apiUrl}/source/api/filters`;
    return this.http.get(api);
  }

  // Data Update dashboard data
  getDataUpdateDashboardData(): Observable<any> {
    let api = `${environment.apiUrl}/source/du_kpi/`;
    return this.http.get(api);
  }

  // Data Delivery dashboard data
  getDataDeliveryDashboardData(): Observable<any> {
    let api = `${environment.apiUrl}/source/data-delivery-summary/`;
    return this.http.get(api);
  }

  // Data Delivery dashboard data
  getDataLibraryDashboardData(): Observable<any> {
    let api = `${environment.apiUrl}/source/data-library/`;
    return this.http.get(api);
  }

  // Home summary data
  getHomeSummary(): Observable<any> {
    let api = `${environment.apiUrl}/uc/home_summary`;
    return this.http.get(api);
  }

  // Status summary data
  getStatusSummary(): Observable<any> {
    let api = `${environment.apiUrl}/uc/status_summary`;
    return this.http.get(api);
  }

  // Update summary table data
  getUpdateSummaryTable(update_id: string): Observable<any> {
    let api = `${environment.apiUrl}/uc/summary_by_batch/${update_id}`;
    return this.http.get(api).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle error here, e.g., return a default value or an error message
        return new Observable<any>((observer) => {
          observer.next([])
          return observer.complete();
        });
      })
    );
  }

  //Get KPIs of a step
  getStepKPI(stepName: string) {
    let api = `${environment.apiUrl}/uc/step_kpi/${stepName}`;
    return this.http.get(api);
  }

  //Get sales information of a step
  getStepSalesInformation(stepName: string) {
    let api = `${environment.apiUrl}/uc/step_sales_kpi/${stepName}`;
    return this.http.get(api);
  }

  //Get updates of a certain status
  getUpdates(status: string, fakeApi = false) {
    if (status === "in_progress") status = "in-progress";
    let api = `${environment.apiUrl}/uc/get_progress/${status}`;
    if (fakeApi) api = `http://localhost:5000/uc/get_progress/${status}`;

    return this.http.get(api);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg = {};
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error,
      };
    }
    return throwError(msg);
  }

  notifySourceUpdate() {
    this.sourceUpdated.emit();
  }

  onSourceUpdates() {
    return this.sourceUpdated;
  }
}

function objectToFormData(source: Source, ignoreEmpty: boolean = false) {
  const formData = new FormData();
  Object.entries(source).forEach(([key, value]) => {
    if (ignoreEmpty && (value === null || value === undefined)) {
      return;
    }

    formData.append(key, value);
  });

  return formData;
}
