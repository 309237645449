<div class="accordion_wrapper">
  <div class="header_wrapper" [ngClass]="{ lower_margin: this.expanded }">
    <p>{{ this.getBatchLabel(update) }}</p>
    <div class="left_side">
      <img [src]="this.getIcon(update)" style="margin-right: -16px" />
      <p [ngStyle]="{ color: this.getColor(update) }">
        {{ this.getDate(update) }}
      </p>
      <div class="expand_icon" (click)="this.toggle()">
        <img [src]="this.getTogglerIcon()" />
      </div>
    </div>
  </div>
  <div class="content_wrapper" *ngIf="this.expanded">
    <ng-container
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: 4 }"
    ></ng-container>
  </div>
</div>
