<div class="brm_cell_editor">
  <ng-container *ngIf="this.editingState && this.column.isEditable">
    <input
      pInputText
      type="text"
      [ngModel]="this.displayValue[this.column.getField()]"
      (blur)="this.change($event)"
      style="width: {{ this.width }}ch;"
      *ngIf="this.column.type === brmColumnType.string"
    />
    <p-calendar
      [dateFormat]="'dd/mm/yy'"
      [ngModel]="this.displayValue[this.column.getField()]"
      (ngModelChange)="this.change($event)"
      *ngIf="this.column.type === brmColumnType.dateTime"
    />
    <p-checkbox
      [ngModel]="this.displayValue[this.column.getField()]"
      (onChange)="this.change($event)"
      [binary]="true"
      *ngIf="this.column.type === brmColumnType.boolean"
    />
    <p-dropdown
      [ngModel]="this.displayValue[this.column.getField()]"
      [options]="this.getListOptions()"
      (onChange)="this.change($event)"
      *ngIf="this.column.type === brmColumnType.list"
    >
      <ng-template pTemplate="selectedItem">
        {{ capitalize(this.displayValue[this.column.getField()]) }}
      </ng-template>
    </p-dropdown>
  </ng-container>
  <ng-container *ngIf="this.editingState && !this.column.isEditable">
    <p style="max-width: {{ this.width }}ch;">
      {{ this.displayValue[this.column.getField()] }}
    </p>
  </ng-container>

  <ng-container *ngIf="!this.editingState">
    <p
      *ngIf="
        this.column.type === brmColumnType.string ||
        this.column.type === brmColumnType.number
      "
      style="max-width: {{ this.width }}ch;"
    >
      {{ this.displayValue[this.column.getField()] }}
    </p>
    <p
      *ngIf="this.column.type === brmColumnType.dateTime"
      style="max-width: {{ this.width }}ch;"
    >
      {{ this.formatDate(this.displayValue[this.column.getField()]) }}
    </p>
    <p-dropdown
      [ngModel]="this.displayValue[this.column.getField()]"
      [options]="this.getListOptions()"
      [disabled]="true"
      *ngIf="this.column.type === brmColumnType.list"
    >
      <ng-template pTemplate="selectedItem">
        {{ capitalize(this.displayValue[this.column.getField()]) }}
      </ng-template>
    </p-dropdown>
    <ng-container *ngIf="this.column.type === brmColumnType.boolean">
      <p-checkbox
        [ngModel]="this.displayValue[this.column.getField()]"
        [binary]="true"
        [disabled]="true"
      />
    </ng-container>
  </ng-container>
</div>
