import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  BooleanBRMColumn,
  booleanColumnFilterCriteria,
  BRMColumn,
  brmColumnType,
  dateColumnFilterCriteria,
  ListBRMColumn,
  listColumnFilterCriteria,
  numberColumnFilterCriteria,
  stringColumnCriteriaMode,
  stringColumnFilterCriteria,
} from "../brm/columns";
import { capitalizeFirstLetter } from "../core/models/helpers";

@Component({
  selector: "app-brm-column-filter",
  templateUrl: "./brm-column-filter.component.html",
  styleUrls: ["./brm-column-filter.component.scss"],
})
export class BrmColumnFilterComponent {
  brmColumnType = brmColumnType;
  @Input() column: BRMColumn | undefined;
  @Output() filterChanged = new EventEmitter<any>();
  @Output() filterReset = new EventEmitter<any>();

  @Output() sorterChanged = new EventEmitter<any>();

  brmSorters = [
    {
      label: "Alphabetical Asc. (A → Z)",
      value: "asc",
    },
    {
      label: "Alphabetical Dsc. (Z → A)",
      value: "dsc",
    },
  ];

  text_filters_options = [
    {
      label: "Starting with",
      value: "starting",
    },
    {
      label: "Containing",
      value: "containing",
    },
    {
      label: "Finishing with",
      value: "finishing",
    },
  ];
  number_filters_options = [
    {
      label: "Equals",
      value: "equals",
    },
    {
      label: "Greater than",
      value: "greater_than",
    },
    {
      label: "Less than",
      value: "less_than",
    },
    {
      label: "Between",
      value: "between",
    },
  ];

  selected_text_filter: string | undefined;
  selected_text_filter_mode = "starting";

  selected_number_filter: number | undefined;
  selected_second_number_filter: number | undefined;
  selected_number_filter_mode = "equals";
  date: Date | undefined;

  getListItems() {
    if (this.column && this.column.type === brmColumnType.list)
      return (this.column as ListBRMColumn).getListItems();
    else if (this.column && this.column.type === brmColumnType.boolean)
      return (this.column as BooleanBRMColumn).getListItems();
    else return [];
  }

  cleanLabel(value: string) {
    return capitalizeFirstLetter(value.replace("_", " "));
  }

  jsonify(obj: any) {
    return JSON.stringify(obj);
  }

  textModeToEnum(textMode: string) {
    const mapping = {
      starting: stringColumnCriteriaMode.starting_with,
      containing: stringColumnCriteriaMode.containing,
      finishing: stringColumnCriteriaMode.finishing_with,
    } as Record<string, stringColumnCriteriaMode>;
    return mapping[textMode];
  }

  selected_list_value: string[] = [];
  truth_values: boolean[] = [];

  reset() {
    this.filterReset.emit(this.column?.field);
  }

  sorter: string | undefined;

  setSorter(newSorter: string) {
    this.sorter = newSorter;
    this.sortingChanged();
  }

  sortingChanged() {
    this.sorterChanged.emit(this.sorter);
  }

  valueChanged() {
    if (this.column && this.column.type === brmColumnType.string) {
      this.filterChanged.emit(
        new stringColumnFilterCriteria(
          this.textModeToEnum(this.selected_text_filter_mode),
          this.selected_text_filter ? this.selected_text_filter : ""
        )
      );
    } else if (
      this.date &&
      this.column &&
      this.column.type === brmColumnType.dateTime
    ) {
      this.filterChanged.emit(new dateColumnFilterCriteria(this.date));
    } else if (
      this.selected_list_value.length > 0 &&
      this.column &&
      this.column.type === brmColumnType.list
    ) {
      this.filterChanged.emit(
        new listColumnFilterCriteria(this.selected_list_value)
      );
    } else if (
      this.truth_values &&
      this.column &&
      this.column.type === brmColumnType.boolean
    ) {
      this.filterChanged.emit(
        new booleanColumnFilterCriteria(this.truth_values)
      );
    } else if (
      this.column &&
      this.column.type === brmColumnType.number &&
      this.selected_number_filter_mode &&
      this.selected_number_filter
    ) {
      if (
        this.selected_number_filter_mode === "between" &&
        !this.selected_second_number_filter
      )
        return;

      let selected_second_number_filter = this.selected_second_number_filter;
      if (!selected_second_number_filter) selected_second_number_filter = 0;
      
      this.filterChanged.emit(
        new numberColumnFilterCriteria(
          this.selected_number_filter_mode,
          parseInt(this.selected_number_filter.toString()),
          parseInt(selected_second_number_filter.toString())
        )
      );
    }
  }
}
