<div class="page_header">
  <app-sub-menu></app-sub-menu>
  <h1 style="margin-top: 40px; margin-bottom: 16px">All Data Sources</h1>
  <div id="data_source_search_bar" class="search_bar">
    <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        type="text"
        placeholder="Search"
        [(ngModel)]="this.search_text"
        pInputText
      />
    </span>
  </div>
  <div id="filters">
    <app-filters
      [filters]="this.filterComponentValues()"
      (filtersChanged)="this.selectedFilterChanged($event)"
    ></app-filters>
    <p-multiSelect
      class="borderless without_border update_status"
      [options]="data_status_options"
      [group]="true"
      [placeholder]="'Update Status'"
      (onChange)="updateStatusChanged($event)"
      [(ngModel)]="this.selected_update_status"
    >
      <ng-template let-group pTemplate="group">
        <span *ngIf="group.label" class="group_label">{{ group.label }}</span>
      </ng-template>
      <ng-template let-value pTemplate="item">
        <div style="width: 100%" (mouseenter)="this.displayOverlay($event, value.value)" (mouseleave)="this.hideOverlay(value.value)">
          {{ value.label }}
        </div>
        <p-overlayPanel #item_overlay [attr.value]="value.value"> 
          <div class="overlay_content">
            <p (click)="this.all_vs_30 = 'all'">All</p>
            <p (click)="this.all_vs_30 = '30_days'">30 days</p>
          </div>
        </p-overlayPanel>
      </ng-template>
      <ng-template let-value pTemplate="selectedItems">
        <div>
          Update Status
          <ng-container *ngIf="this.getCurrentStatusFilterLabel()">
            |
            <span style="color: #8e9397">
              {{ this.getCurrentStatusFilterLabel() }}
            </span>
          </ng-container>
        </div>
      </ng-template>
    </p-multiSelect>

    <a id="reset_button" href="#" (click)="resetAllFilters()">Reset all</a>
  </div>
</div>
<div id="wrapper">
  <div id="sources">
    <source-card
      *ngFor="
        let source of this.sourcesToDisplay
          | sourcesCountryFilter : this.filters['countries'].selected_values
          | sourcesDataTypeFilter : this.filters['data_types'].selected_values
          | sourcesChannelFilter : this.filters['channels'].selected_values
          | sourcesGranularityFilter
            : this.filters['granularities'].selected_values
          | sourcesFrequencyFilter : this.filters['frequencies'].selected_values
          | sourcesSearchTextFilter : this.search_text
          | sourcesDateOrderFilter : this.packCriteria()
          | sourcesStatusFilter : this.packCriteria()
      "
      [id]="source.id"
      [panel_name]="source.panel_name"
      [state]="source.state"
      [quality]="source.quality"
      [end_period]="source.end_period"
      [next_update]="source.next_update"
      [person_name]="source.person_name"
      [country_code]="source.country_code"
      [data_vendor]="source.data_vendor"
      [data_type]="source.data_type"
      [granularity]="source.granularity"
      [update_frequency]="source.update_frequency"
    ></source-card>
  </div>
</div>
