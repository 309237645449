import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-visible-columns",
  templateUrl: "./visible-columns.component.html",
  styleUrls: ["./visible-columns.component.scss"],
})
export class VisibleColumnsComponent {
  columns = [
    "category_sortkey",
    "country_br",
    "market_br",
    "category_master",
    "category_id",
    "category_0",
    "category_operator",
    "category_set",
    "country_operator",
    "country",
    "panel_operator",
    "panel",
    "channel_operator",
    "channel",
    "class_type_operator",
    "class_type",
    "atc3_recode_operator",
    "atc4_recode_operator",
    "atc4_recode",
    "product_recode_operator",
    "product_recode",
    "molecules_recode_operator",
    "molecules_recode",
    "pack_recode_operator",
    "pack_recode",
    "nfc123_recode_operator",
    "nfc123_recode",
  ] as any ;

  @Input() selectedColumns: string [] = [];
  @Output() selectedColumnsChange = new EventEmitter<string []>();

  ngOnInit() {
    console.log(this.selectedColumns);
  }

  columnClicked(event: any, columnName: string){
    if (!this.selectedColumns.includes(columnName))
      this.selectedColumns.push(columnName);
    else
      this.selectedColumns = this.selectedColumns.filter((column: string) => (column!==columnName));

    this.selectedColumnsChange.emit(this.selectedColumns);
  }

  isColumnSelected(columnName: string) {
    return (this.selectedColumns.find((column: any) => (column === columnName)));
  }
}
