import { utc } from "moment";

export function convertUTCToLocal(utcDateObject: Date) {
  const localDate = new Date(utcDateObject.getTime()); // Clone the date

  // Get the time zone offset in minutes
  const timezoneOffset = localDate.getTimezoneOffset();

  // Adjust the date for the offset (add offset in minutes)
  localDate.setMinutes(localDate.getMinutes() + (-timezoneOffset));

  return localDate;
}
