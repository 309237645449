import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sourcesFrequencyFilter'
})
export class SourcesFrequencyFilterPipe implements PipeTransform {

  transform(sources: any [], selectedFrequencies: string []): any [] {
    if (selectedFrequencies.length === 1 && selectedFrequencies[0] === "all"){
      return sources
    }

    return sources.filter((source) => (source.update_frequency && selectedFrequencies.includes(source.update_frequency)));
  }

}
