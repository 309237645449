<div class="activity_log_body">
  <div
    class="timeline"
    [ngStyle]="{
      height: this.height ? this.height.toString() + 'px' : 'inherit'
    }"
  >
    <ng-container
      *ngFor="
        let activity of this.get_activities()
          | selectedCellFilter : this.selected_cell_key;
        let i = index;
        let len = count
      "
    >
      <div class="timeline_profile_picture">
        <div class="line" [ngClass]="{ transparent_line: i === 0 }"></div>
        <img
          src="{{ activity.created_by_profile_picture }}"
          class="activity_profile_image"
        />
        <div class="line" [ngClass]="{ transparent_line: i === len - 1 }"></div>
      </div>
      <div class="activity">
        <div style="display: none" class="brm_button clickable">
          <p>Row #7</p>
          <img src="assets/icons/brm_pointer.png" />
        </div>
        <p class="timeline_message">
          <span>{{ activity.column_name }}</span> updated from
          <span>{{ activity.old_value }}</span>
          to
          <span>{{ activity.new_value }}</span>
        </p>
        <p
          class="timeline_metadata"
          [innerHTML]="this.getMessage(activity)"
        ></p>
        <div
          class="timeline_decision"
          *ngIf="activity.status === 'pending' && this.displayApproveRejection && this.userAtLeastManager"
        >
          <p-button
            class="reject"
            (click)="this.rejectChanges(activity.change_id)"
            >Reject</p-button
          >
          <p-button
            class="approve"
            (click)="this.approveChanges(activity.change_id)"
            >Approve</p-button
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
