import { Chart } from "chart.js";

export const legendColors = ["#F43636"];
export class CustomLegendPlugin {
  id = "customLegendPlugin"; // Unique ID for the plugin

  hovered_sales = "";

  setHoveredSales(hovered_sales: string) {
    this.hovered_sales = hovered_sales;
  }

  beforeDraw(chart: Chart) {
    this.drawCustomLegend(chart);
  }

  drawCustomLegend(chart: Chart) {
    const ctx = chart.ctx;
    const legendContainer = document.createElement("div");
    legendContainer.classList.add("custom-legend"); // Add CSS class for styling
    legendContainer.style.display = "flex";
    legendContainer.style.flexWrap = "wrap";

    // Get legend information from Chart.js (optional)
    const legend = chart.options.plugins?.legend; // Access legend options if available

    let color_index = 0;
    let new_data_sets: any[] = [];

    // Loop through datasets and create legend items
    chart.data.datasets.forEach((dataset, index) => {

      const legendItem = document.createElement("div");
      legendItem.classList.add("legend-item");
      legendItem.style.marginRight = '20px';
      legendItem.style.fontSize = '14px';
      if (this.hovered_sales === "") //No sales is hovered
        legendItem.style.opacity = '1';
      else 
        legendItem.style.display = 'none';

      if (("Sales in "+dataset.label) === this.hovered_sales){
        legendItem.style.display = 'flex';
      }

      const color = legendColors[color_index%legendColors.length];
      dataset.borderColor = color;

      let meta = chart.getDatasetMeta(index);
      if (meta.dataset)
        (meta.dataset as any).borderColor = color;

      // Create color box element
      const svgCode = `<rect y="3.5" width="24" height="2" fill="${color}"/>
                      <rect x="9" y="1.5" width="6" height="6" rx="3" fill="white" stroke="${color}" stroke-width="2"/>`;

      const svgElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      svgElement.innerHTML = svgCode;
      svgElement.style.marginRight = '8px';

      // Create label element
      const label = document.createElement("span");
      label.textContent = dataset.label || "";

      // Optionally, customize label content based on your needs

      // Add elements to legend item
      legendItem.appendChild(svgElement);
      legendItem.appendChild(label);

      legendContainer.appendChild(legendItem);
      color_index++;
    });

    // Remove previous legends
    let previous_legends = chart.canvas.parentElement?.querySelectorAll(".custom-legend");
    if (previous_legends){
      previous_legends.forEach(element => element.remove());
    }

    // Append legend container to the chart container (adjust positioning as needed)
    chart.canvas.parentNode?.insertBefore(
      legendContainer,
      chart.canvas.nextSibling
    );
  }
}
