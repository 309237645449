import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { getCountryName } from "../../core/models/country_codes";
import { Router } from "@angular/router";
import {
  getDataTypeLabel,
  getDataVendorLabel,
  getGranularityLabel,
  getUpdateFrequencyLabel,
} from "src/app/landing/my/filters-data";

export enum SourceState {
  Inactive,
  Pending,
  Completed,
  InProgress,
}

export const SourceStateStringToEnum: Record<string, SourceState> = {
  inactive: SourceState.Inactive,
  pending: SourceState.Pending,
  completed: SourceState.Completed,
  "in-progress": SourceState.InProgress,
};

@Component({
  selector: "source-card",
  templateUrl: "./source-card.component.html",
  styleUrls: ["./source-card.component.scss"],
})
export class SourceCardComponent {
  @Input() id: string = "";
  @Input() panel_name: string = "";
  @Input() state: SourceState = SourceState.InProgress;
  @Input() quality: number = 99;
  @Input() end_period: Date = new Date();
  @Input() next_update: Date | null = null;
  @Input() person_name: string = "";
  @Input() country_code: string = "";
  @Input() data_vendor: string = "";
  @Input() data_type: string = "";
  @Input() granularity: string = "";
  @Input() update_frequency: string = "";

  tags_expanded = false;

  constructor(private router: Router) {}
  ngOnInit() {}

  goToSourceDetails() {
    this.router.navigate(["source_details", this.id]);
  }

  getSourceName() {
    return this.getCountryName() + "-" + this.panel_name;
  }

  getTags() {
    return [
      getDataVendorLabel(this.data_vendor),
      getDataTypeLabel(this.data_type),
      getGranularityLabel(this.granularity),
      getUpdateFrequencyLabel(this.update_frequency),
    ];
  }

  toggleCollapse(event: Event) {
    event.stopPropagation();
    this.tags_expanded = !this.tags_expanded;
  }

  getCurrentSourceStateCaption() {
    switch (this.state) {
      case SourceState.Inactive:
        return "Inactive";
        break;

      case SourceState.Pending:
        return "Pending...";
        break;

      case SourceState.Completed:
        return "Completed";
        break;

      case SourceState.InProgress:
        return "In progress";
        break;

      default:
        return "";
        break;
    }
  }

  getCurrentSourceStateIcon() {
    let icon_path = "";
    switch (this.state) {
      case SourceState.Inactive:
        icon_path = "inactive.svg";
        break;

      case SourceState.Pending:
        icon_path = "pending.svg";
        break;

      case SourceState.Completed:
        icon_path = "completed.svg";
        break;

      case SourceState.InProgress:
        icon_path = "in_progress.svg";
        break;

      default:
        break;
    }

    return `assets/icons/sources/${icon_path}`;
  }

  getFooterColorClass() {
    switch (this.state) {
      case SourceState.Inactive:
        return "status_inactive";
        break;

      case SourceState.Pending:
        return "status_pending";
        break;

      case SourceState.Completed:
        return "status_completed";
        break;

      case SourceState.InProgress:
        return "status_in_progress";
        break;

      default:
        return "";
        break;
    }
  }

  isCompleted() {
    return this.state === SourceState.Completed;
  }

  getFormattedEndPeriod() {
    if (
      !this.end_period ||
      this.end_period?.toDateString() === "Invalid Date"
    )
    return "";

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${
      monthNames[this.end_period.getMonth()]
    } ${this.end_period.getFullYear()}`;
  }

  getFormattedNextUpdateDate() {
    if (
      !this.next_update ||
      this.next_update?.toDateString() === "Invalid Date"
    )
      return "";

    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${
      monthAbbreviations[this.next_update.getMonth()]
    } ${this.next_update.getDate()}, ${this.next_update.getFullYear()}`;
  }

  getCountryIcon() {
    return `assets/icons/countries/${this.country_code}.svg`;
  }

  getCountryName() {
    return getCountryName(this.country_code);
  }
}
