import * as moment from "moment";
import { convertUTCToLocal } from "../core/utils/dateTime";

export enum brmColumnType {
  dateTime,
  boolean,
  list,
  string,
  number,
}

export interface brmColumn {
  type: brmColumnType;
  field: string;
  displayOverflow: boolean;

  getHeader(): string;
  getField(): string;
}

export class BRMColumn implements brmColumn {
  type = brmColumnType.string;
  displayOverflow = false;
  isEditable: boolean;
  field = "";

  constructor(field: string, isEditable = true) {
    this.field = field;
    this.isEditable = isEditable;
  }

  getHeader() {
    return this.field
      .split("_")
      .map((part: string) => capitalize(part))
      .join(" ");
  }

  getField() {
    return this.field;
  }

  getProperValue(value: Record<string, any>): any {
    return value[this.field];
  }
}

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export class DateTimeBRMColumn extends BRMColumn implements brmColumn {
  override type = brmColumnType.dateTime;
  override displayOverflow = true;

  override getProperValue(value: Record<string, any>): any {
    return convertUTCToLocal(
      moment(value[this.field], "YYYY-MM-DD HH:mm:ss.SSSSSS").toDate()
    );
  }
}

export class NumberBRMColumn extends BRMColumn implements brmColumn {
  override type = brmColumnType.number;
  override displayOverflow = true;
}

export class BooleanBRMColumn extends BRMColumn implements brmColumn {
  override type = brmColumnType.boolean;
  true_label: string;
  false_label: string;

  constructor(field: string, true_label: string, false_label: string) {
    super(field);
    this.true_label = true_label;
    this.false_label = false_label;
  }

  getListItems() {
    return [
      {
        value: true,
        label: this.true_label,
      },
      {
        value: false,
        label: this.false_label,
      },
    ];
  }

  override getProperValue(value: Record<string, any>): boolean {
    return value[this.field];
  }
}

export class StringBRMColumn extends BRMColumn implements brmColumn {
  override type = brmColumnType.string;
}

export class ListBRMColumn extends BRMColumn implements brmColumn {
  override type = brmColumnType.list;
  override displayOverflow = true;
  list_items = [] as any[];

  constructor(field: string, items: any[]) {
    super(field);
    this.list_items = items;
  }

  getListItems() {
    return this.list_items;
  }
}

export enum stringColumnCriteriaMode {
  starting_with,
  containing,
  finishing_with,
}

export class stringColumnFilterCriteria implements brmFilterCriteria {
  mode: stringColumnCriteriaMode;
  value: string;

  constructor(mode: stringColumnCriteriaMode, value: string) {
    this.mode = mode;
    this.value = value;
  }

  getPredicate(): (row_value: string) => boolean {
    return (row_value: string) => {
      if (this.mode === stringColumnCriteriaMode.starting_with) {
        return row_value.startsWith(this.value);
      } else if (this.mode === stringColumnCriteriaMode.containing) {
        return row_value.includes(this.value);
      } else {
        return row_value.endsWith(this.value);
      }
    };
  }
}

export class numberColumnFilterCriteria implements brmFilterCriteria {
  mode: string;
  value: number;
  secondValue: number;

  constructor(mode: string, value: number, secondValue: number = 0) {
    this.mode = mode;
    this.value = value;
    this.secondValue = secondValue;
  }

  predicates = {
    equals: (test_value: number, n: number) => test_value === n,
    greater_than: (test_value: number, n: number) => test_value >= n,
    less_than: (test_value: number, n: number) => test_value <= n,
  } as Record<string, (n1: number, n2: number) => boolean>;

  getPredicate(): (row_value: number) => boolean {
    return (row_value: number) => {
      if (Object.keys(this.predicates).includes(this.mode)) {
        return this.predicates[this.mode](row_value, this.value);
      } else {
        return this.value <= row_value && row_value <= this.secondValue;
      }
    };
  }
}

export class dateColumnFilterCriteria implements brmFilterCriteria {
  date: Date;

  constructor(date: Date) {
    this.date = date;
  }

  getPredicate(): (row_value: Date) => boolean {
    return (value: Date) => isSameDay(value, this.date);
  }
}

export class listColumnFilterCriteria implements brmFilterCriteria {
  values: string[];

  constructor(values: string[]) {
    this.values = values;
  }

  getPredicate(): (row_value: string) => boolean {
    return (value: string) => this.values.includes(value.toLowerCase());
  }
}

export class booleanColumnFilterCriteria implements brmFilterCriteria {
  turth_values: boolean[];

  constructor(truth_values: boolean[]) {
    this.turth_values = truth_values;
  }

  getPredicate(): (row_value: boolean) => boolean {
    return (value: boolean) => this.turth_values.includes(value);
  }
}

export interface brmFilterCriteria {
  getPredicate(): (value: any) => boolean;
}

function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
