import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "selectedCellFilter",
  pure: false,
})
export class selectedCellFilter implements PipeTransform {
  transform(activities: any[], selected_cell: object | undefined): any[] {
    console.log("my cell", selected_cell);
    if (selected_cell) {
      console.log("selected_cell", selected_cell);
      if (activities.length > 0) console.log("sample activity", activities[0]);
      return activities.filter((activity: any) =>
        this.equalKeys(selected_cell, activity.change_key)
      );
    } else return activities;
  }

  equalKeys(changeKey: any, rule: any) {
    console.log("first key", changeKey);
    console.log("second key", rule);

    for (const [key, value] of Object.entries(changeKey)) {
      if (rule[key] !== changeKey[key]) return false;
    }

    console.log("keys match!")
    return true;
  }
}
