import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { SourceService } from "../core/services/source.service";
import { wrapArrayInTreeNode } from "../mine-table/data-group";
import { TreeNode } from "primeng/api";
import { MultiSelectChangeEvent } from "primeng/multiselect";

@Component({
  selector: "app-quality-center-filters",
  templateUrl: "./quality-center-filters.component.html",
  styleUrls: ["./quality-center-filters.component.scss"],
})
export class QualityCenterFiltersComponent implements OnInit {
  ngOnInit(): void {
    this.stepSelectionChangedEvent.emit(this.selected_steps);
  }

  stepSelectionChanged($event: MultiSelectChangeEvent) {
    const currentSelectedSteps = $event.value;
    const newValue = $event.itemValue.value;
    if (newValue === "all") {
      if (currentSelectedSteps.includes("all"))
        this.selected_steps = this.steps.map((step: any) => step.value);
      else this.selected_steps = [];
    } else {
      const allSteps = this.steps
        .map((step: any) => step.value)
        .filter((step: string) => step !== "all");
      if (!this.arraysHaveSameValues(currentSelectedSteps, allSteps)) {
        this.selected_steps = currentSelectedSteps.filter(
          (step: string) => step !== "all"
        );
      } else {
        this.selected_steps = ["all", ...allSteps];
      }
    }

    this.stepSelectionChangedEvent.emit(this.selected_steps);
  }

  arraysHaveSameValues(array1: any[], array2: any[]) {
    // Ensure both arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }

    // Create a frequency map for each array
    const frequencyMap1 = new Map();
    const frequencyMap2 = new Map();

    for (const element of array1) {
      frequencyMap1.set(element, (frequencyMap1.get(element) || 0) + 1);
    }

    for (const element of array2) {
      frequencyMap2.set(element, (frequencyMap2.get(element) || 0) + 1);
    }

    // Compare the frequency maps
    for (const [key, value] of frequencyMap1) {
      if (frequencyMap2.get(key) !== value) {
        return false;
      }
    }

    return true;
  }

  arraysEqual(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  sourcesValues = [
    { label: "Germany-DKM", value: "Germany-DKM" },
    { label: "England-DKM", value: "Germany-DKM" },
  ];

  steps = [
    { label: "All Steps", value: "all" },
    { label: "Cleaning", value: "cleaning" },
    { label: "Consolidation", value: "consolidation" },
    { label: "Referencing", value: "referencing" },
    { label: "Recoding", value: "recoding" },
    { label: "Categorizing", value: "categorization" },
    { label: "Transcoding", value: "transcoding" },
  ];

  periods = [
    { label: "This week", value: "this_week" },
    { label: "This month", value: "this_month" },
    { label: "This year", value: "this_year" },
    { label: "Past 7 days", value: "past_7_days" },
    { label: "Past 30 days", value: "past_30_days" },
    { label: "Past year", value: "past_year" },
  ];

  updates = [
    { label: "Batch", value: "batch" },
    { label: "Source", value: "source" },
  ];

  selected_periods = [] as any[];
  selected_steps: string[] = this.steps.map((step: any) => step.value);
  @Output() stepSelectionChangedEvent = new EventEmitter<any>();

  getSourcesValues() {
    return this.sourcesValues;
  }

  periodChanged(event: any) {
    this.selected_periods = [
      this.selected_periods[this.selected_periods.length - 1],
    ];
  }
}
