import { Pipe, PipeTransform } from "@angular/core";
import { getCountryName } from "src/app/core/models/country_codes";

@Pipe({
  name: "sourcesSearchTextFilter",
})
export class SourcesSearchTextFilterPipe implements PipeTransform {
  transform(sources: any[], searchTerm: string): any[] {
    const term = searchTerm.trim().toLocaleLowerCase();
    if (term === "") return sources;
    else
      return sources.filter((source) =>
        (getCountryName(source.country_code) + "-" + source.panel_name)
          .toLocaleLowerCase()
          .includes(term)
      );
  }
}
