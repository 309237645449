<div class="filter_container">
  <div
    class="brm_sort"
    *ngIf="this.column && this.column.type === brmColumnType.string"
  >
    <p class="filter_title">SORT BY</p>
    <div class="brm_sorters">
      <ng-container *ngFor="let sorter of this.brmSorters">
        <div class="sorter clickable" (click)="this.setSorter(sorter.value)">
          <img src="/assets/icons/brm_{{ sorter.value }}.svg" alt="" />
          <p>
            {{ sorter.label }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="brm_filter"
    *ngIf="this.column && this.column.type === brmColumnType.number"
  >
    <p class="filter_title">FILTER BY VALUE</p>
    <div class="brm_filter">
      <p-dropdown
        [options]="number_filters_options"
        [optionValue]="'value'"
        [(ngModel)]="selected_number_filter_mode"
      >
        <ng-template pTemplate="selectedItem">
          {{ this.cleanLabel(this.selected_number_filter_mode) }}
        </ng-template>
        <ng-template let-item pTemplate="item"> {{ item.label }} </ng-template>
      </p-dropdown>
      <div class="number_filter_between_container">
        <input pInputText [(ngModel)]="selected_number_filter" placeholder="Value"/>
        <p *ngIf="this.selected_number_filter_mode==='between'">and</p>
        <input pInputText [(ngModel)]="selected_second_number_filter" *ngIf="this.selected_number_filter_mode==='between'" placeholder="Value"/>
      </div>
    </div>
  </div>
  <div
    class="brm_filter"
    *ngIf="this.column && this.column.type === brmColumnType.string"
  >
    <p class="filter_title">FILTER BY VALUE</p>
    <div class="brm_filter">
      <p-dropdown
        [options]="text_filters_options"
        [optionValue]="'value'"
        [(ngModel)]="selected_text_filter_mode"
      >
        <ng-template pTemplate="selectedItem">
          {{ this.selected_text_filter_mode }}
        </ng-template>
        <ng-template let-item pTemplate="item"> {{ item.label }} </ng-template>
      </p-dropdown>
      <input pInputText [(ngModel)]="selected_text_filter" />
    </div>
  </div>
  <div
    class="brm_filter"
    *ngIf="this.column && this.column.type === brmColumnType.dateTime"
  >
    <p class="filter_title">FILTER BY Date</p>
    <div class="brm_filter">
      <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="this.date"></p-calendar>
    </div>
  </div>
  <div
    class="brm_filter"
    *ngIf="this.column && this.column.type === brmColumnType.list"
  >
    <p class="filter_title">FILTER BY {{ this.column.getHeader() }}</p>
    <div class="brm_filter">
      <p-checkbox
        [(ngModel)]="this.selected_list_value"
        *ngFor="let item of this.getListItems()"
        label="{{ item['label'] }}"
        value="{{ item['value'] }}"
      />
    </div>
  </div>
  <div
    class="brm_filter"
    *ngIf="this.column && this.column.type === brmColumnType.boolean"
  >
    <p class="filter_title">FILTER BY {{ this.column.getHeader() }}</p>
    <div class="brm_filter">
      <p-checkbox
        [(ngModel)]="this.truth_values"
        [label]="this.getListItems()[0]['label']"
        [value]="this.getListItems()[0]['value']"
      />
      <p-checkbox
        [(ngModel)]="this.truth_values"
        [label]="this.getListItems()[1]['label']"
        [value]="this.getListItems()[1]['value']"
      />
    </div>
  </div>
  <div class="brm_filter_buttons">
    <a class="clickable" (click)="this.reset()">Reset</a>
    <p-button (onClick)="this.valueChanged()">Apply</p-button>
  </div>
</div>
