import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { MessagesModule } from 'primeng/messages';
import { ThemeService } from './core/services/theme.service';
import { SharedSubjectService } from './core/services/shared-subject.service';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NewListComponent } from './new-list/new-list.component';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { SourceCardComponent } from './shared/source-card/source-card.component';
import { MenuItemComponent } from './shared/menu-item/menu-item.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { SourceDetailComponent } from './source-details/source-details.component';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DataSourceFormComponent } from './data-source-form/data-source-form.component';
import { ConfirmationService } from "primeng/api";
import { DataSourceDialogComponent } from './data-source-dialog/data-source-dialog.component';
import { TooltipModule } from 'primeng/tooltip';
import { UploadComponentComponent } from './shared/upload-component/upload-component.component';
import { CheckboxControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { SupportedFormatsComponent } from './shared/supported-formats/supported-formats.component';
import { RefreshDataSourceComponent } from './shared/refresh-data-source/refresh-data-source.component';
import { NewDataSourceDialogComponent } from './shared/new-data-source-dialog/new-data-source-dialog.component';
import { PlaceHolderComponent } from './place-holder/place-holder.component';
import { InputNumber, InputNumberModule } from 'primeng/inputnumber';
import { HomeComponentComponent } from './home-component/home-component.component';
import { StepsComponentComponent } from './shared/steps-component/steps-component.component';
import { UpdateComponent } from './update/update.component';
import { AccordionModule } from 'primeng/accordion';
import { SourcesCountryFilterPipe } from './new-list/pipes/country-filter.pipe';
import { SourcesDataTypeFilterPipe } from './new-list/pipes/data-type-filter.pipe';
import { SourcesChannelFilterPipe } from './new-list/pipes/channel-filter.pipe';
import { SourcesFrequencyFilterPipe } from './new-list/pipes/frequency-filter.pipe';
import { SourcesGranularityFilterPipe } from './new-list/pipes/granularity-filter.pipe';
import { SourcesSearchTextFilterPipe } from './new-list/pipes/search-text-filter.pipe';
import { SourcesDateOrderFilterPipe } from './new-list/pipes/order-by-dates.pipe';
import { RepeatDialogComponent } from './repeat-dialog/repeat-dialog.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { ToastModule } from 'primeng/toast';
import { MineTableComponent } from './mine-table/mine-table.component';
import { FiltersComponent } from './filters/filters.component';
import { AttributesChartsComponent } from './attributes-charts/attributes-charts.component';
import { TreeTableModule } from 'primeng/treetable';
import { QualityCenterDashboardComponent } from './quality-center-dashboard/quality-center-dashboard.component';
import { QualityCenterFiltersComponent } from './quality-center-filters/quality-center-filters.component';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { updatesCountryFilterPipe } from './update/pipes/country-filter.pipe';
import { updatesDataTypeFilterPipe } from './update/pipes/data-type-filter.pipe';
import { updatesChannelFilterPipe } from './update/pipes/channel-filter.pipe';
import { updatesGranularityFilterPipe } from './update/pipes/granularity-filter.pipe';
import { updatesFrequencyFilterPipe } from './update/pipes/frequency-filter.pipe';
import { BarsChartComponent } from './bars-chart/bars-chart.component';
import { BRMComponent } from './brm/brm.component';
import { VisibleColumnsComponent } from './visible-columns/visible-columns.component';
import { ConfirmChangesDialogComponent } from './confirm-changes-dialog/confirm-changes-dialog.component';
import { Overlay, OverlayModule } from 'primeng/overlay';
import { PickListModule } from 'primeng/picklist';
import { BrmCellEditorComponent } from './brm-cell-editor/brm-cell-editor.component';
import { SourcesStatusFilter } from './new-list/pipes/status-filter.pipe';
import { BrmColumnFilterComponent } from './brm-column-filter/brm-column-filter.component';
import { RowFilterPipe } from './brm/pipes/row-filter.pipe';
import { BatchEditDialogComponent } from './batch-edit-dialog/batch-edit-dialog.component';
import { MineDropdownComponent } from './shared/mine-dropdown/mine-dropdown.component';
import { ListboxModule } from 'primeng/listbox';
import { SettingsComponent } from './settings/settings.component';
import { MenuModule } from 'primeng/menu';
import { ConfirmChangePasswordComponent } from './confirm-change-password/confirm-change-password.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { BrmActivityLogComponent } from './brm-activity-log/brm-activity-log.component';
import { ChangesFilterPipe } from './brm/pipes/change-filter.pipe';
import { selectedCellFilter } from './brm-activity-log/pipes/selected-cell-filter.pipe';
import { PresetsEditorComponent } from './presets-editor/presets-editor.component';
import { ApplyChangesFilter } from './brm/pipes/apply-changes.pipe';
import { MineDateTimeEditorComponent } from './mine-date-time-editor/mine-date-time-editor.component';
import { MineAccordionComponent } from './mine-accordion/mine-accordion.component';
import { ProgressBarModule } from "primeng/progressbar";
import { SorterFilterPipe } from './brm/pipes/sorter-filter.pipe';
import { KPIsStepsFilter } from './quality-center-dashboard/pipes/kpi-steps-filter.pipe';
import { BrmActivityHistoryComponent } from './brm-activity-history/brm-activity-history.component';
import { ConfirmBatchDecisionComponent } from './confirm-batch-decision/confirm-batch-decision.component';

@NgModule({
  declarations: [
    AppComponent,
    NewListComponent,
    SourceCardComponent,
    MenuItemComponent,
    SourceDetailComponent,
    DashboardLayoutComponent,
    DataSourceFormComponent,
    DataSourceDialogComponent,
    UploadComponentComponent,
    SupportedFormatsComponent,
    RefreshDataSourceComponent,
    NewDataSourceDialogComponent,
    PlaceHolderComponent,
    HomeComponentComponent,
    StepsComponentComponent,
    UpdateComponent,
    SourcesCountryFilterPipe,
    SourcesDataTypeFilterPipe,
    SourcesChannelFilterPipe,
    SourcesGranularityFilterPipe,
    SourcesStatusFilter,
    SourcesFrequencyFilterPipe,
    updatesCountryFilterPipe,
    updatesDataTypeFilterPipe,
    updatesChannelFilterPipe,
    updatesGranularityFilterPipe,
    updatesFrequencyFilterPipe,
    SourcesSearchTextFilterPipe,
    SourcesDateOrderFilterPipe,
    RepeatDialogComponent,
    SubMenuComponent,
    MineTableComponent,
    FiltersComponent,
    AttributesChartsComponent,
    QualityCenterDashboardComponent,
    QualityCenterFiltersComponent,
    BarsChartComponent,
    BRMComponent,
    VisibleColumnsComponent,
    ConfirmChangesDialogComponent,
    BrmCellEditorComponent,
    BrmColumnFilterComponent,
    RowFilterPipe,
    SorterFilterPipe,
    ChangesFilterPipe,
    selectedCellFilter,
    ApplyChangesFilter,
    BatchEditDialogComponent,
    MineDropdownComponent,
    SettingsComponent,
    ConfirmChangePasswordComponent,
    OnboardingComponent,
    BrmActivityLogComponent,
    PresetsEditorComponent,
    MineDateTimeEditorComponent,
    MineAccordionComponent,
    KPIsStepsFilter,
    BrmActivityHistoryComponent,
    ConfirmBatchDecisionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    ButtonModule,
    MessagesModule,
    ProgressSpinnerModule,
    DropdownModule,
    CardModule,
    MultiSelectModule,
    TableModule,
    ChartModule,
    InputTextModule,
    ConfirmDialogModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    TooltipModule,
    InputNumberModule,
    AccordionModule,
    ToastModule,
    TreeTableModule,
    ButtonModule,
    CheckboxModule,
    OverlayPanelModule,
    PickListModule,
    BrowserAnimationsModule,
    ListboxModule,
    MenuModule,
    ProgressBarModule
  ],
  providers: [
    ThemeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    SharedSubjectService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
