import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent {
  @Input() icon: string = "file_lock";
  @Input() isActive: boolean = false;

  iconPath() {
    const path = this.isActive ? `hover/${this.icon}.svg` : `${this.icon}.svg`;
    return `assets/icons/menu/main_menu/${path}`;
  }
}
