<div id="calendar_editor_container">
  <button (click)="op.toggle($event)">AAAA</button>
  <p-overlayPanel #op>
    <div id="overlay_content">
      <p-calendar [inline]="true"></p-calendar>
      <p>Time</p>
      <div>
        <input pInputText placeholder="hh:mm" />
        <img src="/assets/icons/brm_date_time_icon.png" id="date_time_icon" />
      </div>
    </div>
  </p-overlayPanel>
</div>
