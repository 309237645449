<div id="dialog_wrapper">
  <p-confirmDialog key="confirm_batch_decision_dialog">
    <ng-template pTemplate="header">
      <div>
        <h2>{{ this.getDialogHeader() }}</h2>
      </div>
      <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
      <div id="content">
        <img [src]="this.getIcon()" alt="" />
        <div id="message">
          <h2>{{ this.getMessageHeader() }}</h2>
          <p>{{ this.getMessage() }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        class="white_button"
        (onClick)="closeDialog()"
        *ngIf="!this.isConfirmed"
        >Cancel</p-button
      >
      <p-button
        id="confirm_button"
        (onClick)="confirm()"
        *ngIf="!this.isConfirmed"
      >
        Confirm
      </p-button>
      <p-button
        id="confirm_button"
        (onClick)="closeDialog()"
        *ngIf="this.isConfirmed"
      >
        Done
      </p-button>
    </ng-template>
  </p-confirmDialog>
</div>
