import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import {
  BRMColumn,
  brmColumnType,
  DateTimeBRMColumn,
  ListBRMColumn,
  StringBRMColumn,
} from "../brm/columns";
import { BatchEdit } from "../brm/undoQueue";

@Component({
  selector: "app-batch-edit-dialog",
  templateUrl: "./batch-edit-dialog.component.html",
  styleUrls: ["./batch-edit-dialog.component.scss"],
})
export class BatchEditDialogComponent {
  brmColumnType = brmColumnType;

  @Input() column: BRMColumn = new DateTimeBRMColumn("toutou");
  @Input() id_list: object[] = [];

  boolean_value = false;
  string_value = "";
  list_value: string | undefined;
  list_options: Record<string, string>[] | undefined;
  date_value: Date = new Date();

  @Output() newValueSubmitted = new EventEmitter<BatchEdit>();

  constructor(private confirmationSerivce: ConfirmationService) {}

  getItems() {
    if (this.column.type === brmColumnType.list) {
      return (this.column as ListBRMColumn).getListItems();
    }
    return [];
  }

  submit() {
    let newEdit;
    console.log(this.id_list);

    if (this.column.type === this.brmColumnType.boolean) {
      newEdit = new BatchEdit(
        this.id_list,
        this.column.getField(),
        this.boolean_value
      );
    } else if (
      this.column.type === this.brmColumnType.list &&
      this.list_value
    ) {
      newEdit = new BatchEdit(this.id_list, this.column.getField(), this.list_value);
    } else if (this.column.type === this.brmColumnType.dateTime) {
      newEdit = new BatchEdit(this.id_list, this.column.getField(), this.date_value);
    } else if (this.column.type === this.brmColumnType.string) {
      newEdit = new BatchEdit(
        this.id_list,
        this.column.getField(),
        this.string_value
      );
    }

    this.newValueSubmitted.emit(newEdit);
  }

  closeDialog() {
    this.confirmationSerivce.close();
  }
}
