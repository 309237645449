import { Pipe, PipeTransform, Predicate } from "@angular/core";
import { sourceCriteria } from "../sourceCriteria";
import { Source } from "src/app/core/models/source";
import {
  isActivated,
  isActivatedInLastMonth,
  isAwaitingDelivery,
  isCompleted,
  isDeactivated,
  isDelayed,
  isInProgress,
  isPending,
} from "../sourcePredicates";
import { filter } from "rxjs";

@Pipe({
  name: "sourcesStatusFilter",
})
export class SourcesStatusFilter implements PipeTransform {
  predicates: Record<string, (source: Source) => boolean> = {
    created: (source: Source) => (true),
    activated: isActivated,
    deactivated: isDeactivated,
    activated_in_last_month: isActivatedInLastMonth,
    decativated_in_last_month: isDeactivated,
    awaiting_delivery: isAwaitingDelivery,
    delayed: isDelayed,
    pending: isPending,
    in_progress: isInProgress,
    completed: isCompleted,
  };

  transform(sources: any[], criteria: sourceCriteria): any[] {
    const filters = criteria.filters;
    const currentPredicates = filters.map(
      (filter: string) => this.predicates[filter]
    );
    console.log("filters: ", filters);
    console.log("predicates: ", currentPredicates);

    return sources.filter((source) =>
      this.allFunctionsPass(source, currentPredicates)
    );
  }

  allFunctionsPass(
    object: Source,
    functions: ((obj: Source) => boolean)[]
  ): boolean {
    for (const func of functions) {
      if (!func(object)) {
        return false;
      }
    }
    return true;
  }
}
