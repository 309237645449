import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "src/environments/environment";
import { Observable, map, catchError, throwError, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class AccountService {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  account: any = null;
  constructor(private http: HttpClient) {}

  // User Account
  getMyAccount(): Observable<any> {
    if (this.account !== null) {
      return of(this.account);
    }

    let api = `${environment.apiUrl}/account/my`;

    return this.http.get(api, { headers: this.headers });
  }

  skipOnBoarding(): Observable<any> {
    let api = `${environment.apiUrl}/account/skip_onboarding/`;

    return this.http.post(api, { headers: this.headers });
  }

  sendVerificationEmail() {
    let api = `${environment.apiUrl}/account/forget_password/`;

    return this.http.post(api, { headers: this.headers });
  }

  // generate jwt
  getJwt(ctx: number): Observable<any> {
    let api = `${environment.apiUrl}/account/generate-jwt/${ctx}`;
    return this.http
      .get(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let msg = {};
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error,
      };
    }
    return throwError(msg);
  }
}
