<div id="filters_wrapper">
  <p-multiSelect id="steps_list" [options]="this.steps" placeholder="Steps" class="borderless" [ngModel]="this.selected_steps" (onChange)="this.stepSelectionChanged($event)">
    <ng-template let-value pTemplate="selectedItems">
      <div>Steps</div>
    </ng-template>
    <ng-template pTemplate="filter"> </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="mine_multiselect_item" style="gap: 10px">
        <img src="assets/icons/steps/black/{{ item.value }}.svg" />
        <p>
          {{ item.label }}
        </p>
      </div>
    </ng-template>
  </p-multiSelect>
</div>
