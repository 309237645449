import { Component, OnInit } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { AccountService } from "../core/services/account.service";

@Component({
  selector: "app-confirm-change-password",
  templateUrl: "./confirm-change-password.component.html",
  styleUrls: ["./confirm-change-password.component.scss"],
})
export class ConfirmChangePasswordComponent implements OnInit {
  constructor(
    private confirmationSerivce: ConfirmationService,
    private accountService: AccountService
  ) {}
  
  isConfirmed = false;

  ngOnInit(): void {
    this.isConfirmed = false;
  }
  
  sendVerificationEmail() {
    this.accountService.sendVerificationEmail().subscribe((res) => {
      this.isConfirmed = true;
    });
  }

  closeDialog() {
    this.confirmationSerivce.close();
    this.isConfirmed = false;
  }
}
