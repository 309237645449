<div id="dialog_wrapper">
  <p-confirmDialog key="batch_edit_dialog">
    <ng-template pTemplate="header">
      <div>
        <h2>Edit Attribute</h2>
        <h3>
          Enter value for bulk edit, be careful this will apply the new value
          for all selected rows.
        </h3>
      </div>
      <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
      <p class="form_label">{{ this.column.getHeader() }}</p>
      <input
        [(ngModel)]="this.string_value"
        pInputText
        *ngIf="this.column.type === this.brmColumnType.string"
      />
      <p-dropdown
        [(ngModel)]="this.list_value"
        [options]="this.getItems()"
        *ngIf="this.column.type === this.brmColumnType.list"
      />
      <p-checkbox
        [binary]="true"
        [(ngModel)]="this.boolean_value"
        *ngIf="this.column.type === this.brmColumnType.boolean"
      />
      <p-calendar
        [(ngModel)]="this.date_value"
        *ngIf="this.column.type === this.brmColumnType.dateTime"
      />
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button class="white_button" (onClick)="closeDialog()">Cancel</p-button>
      <p-button id="confirm_button" (onClick)="submit()">Confirm</p-button>
    </ng-template>
  </p-confirmDialog>
</div>
