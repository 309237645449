import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-place-holder",
  templateUrl: "./place-holder.component.html",
  styleUrls: ["./place-holder.component.scss"],
})
export class PlaceHolderComponent implements OnInit {
  myForm: FormGroup;

  constructor() {
    this.myForm = new FormGroup({"list_value": new FormControl()});
  }

  ngOnInit() {
  }

  submit() {
    alert(JSON.stringify(this.myForm.value));
  }
}
