import { Component, Input } from "@angular/core";

@Component({
  selector: "app-bars-chart",
  templateUrl: "./bars-chart.component.html",
  styleUrls: ["./bars-chart.component.scss"],
})
export class BarsChartComponent {
  @Input() delays_data: any;
  @Input() delays_chart_options: any;
}
