import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-presets-editor',
  templateUrl: './presets-editor.component.html',
  styleUrls: ['./presets-editor.component.scss']
})
export class PresetsEditorComponent {
  @Input() presets: string [] = ["New", "Updated", "Approved"];
  @Input() editMode: boolean = true;
}
