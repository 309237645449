<form [formGroup]="form">
  <div id="form_wrapper">
    <h2>General</h2>
    <div class="details">
      <div
        [class.invalid]="!form.get('country')?.valid && this.formSubmitted"
        id="countries"
      >
        <label for="">Country*</label>
        <br />
        <p-multiSelect
          [options]="countries"
          class="borderless"
          placeholder="Select country"
          formControlName="country"
        >
        </p-multiSelect>
      </div>
      <div
        [class.invalid]="!form.get('data_vendor')?.valid && this.formSubmitted"
      >
        <label for="">Data Vendor*</label>
        <br />
        <p-dropdown
          [options]="data_providers"
          placeholder="Select data vendor"
          formControlName="data_vendor"
        ></p-dropdown>
      </div>
      <div [class.invalid]="!form.get('panel')?.valid && this.formSubmitted">
        <label for="">Panel Name*</label>
        <br />
        <input
          type="text"
          pInputText
          placeholder="Select Panel Name"
          formControlName="panel"
        />
      </div>
      <div>
        <label for="">Panel Group</label>
        <br />
        <p-dropdown
          [options]="panel_groups"
          placeholder="Select panel group"
          formControlName="panel_group"
        ></p-dropdown>
      </div>
      <div [class.invalid]="!form.get('contact_owner_name')?.valid && this.formSubmitted">
        <label for="">Contact Owner Name*</label>
        <br />
        <input
          type="text"
          placeholder="Contact Owner Name"
          formControlName="contact_owner_name"
          pInputText
        />
      </div>
      <div [class.invalid]="!form.get('contact_owner_email')?.valid && this.formSubmitted">
        <label for="">Contact Owner Email*</label>
        <br />
        <input
          type="text"
          placeholder="Contact Owner Email"
          formControlName="contact_owner_email"
          pInputText
        />
      </div>
      <div [class.invalid]="!form.get('scope_of_subscription')?.valid && this.formSubmitted">
        <label for="">Scope of Subscription*</label>
        <br />
        <p-dropdown
          [options]="scopes_of_subscription"
          placeholder="Select scope of subscription"
          formControlName="scope_of_subscription"
        ></p-dropdown>
      </div>
    </div>
    <h2>Panel Content</h2>
    <div class="details">
      <div
        [class.invalid]="!form.get('data_type')?.valid && this.formSubmitted"
      >
        <label for="">Type of Data*</label>
        <br />
        <p-dropdown
          [options]="data_type_list"
          placeholder="Select type of data"
          formControlName="data_type"
        ></p-dropdown>
      </div>
      <div
        [class.invalid]="!form.get('granularity')?.valid && this.formSubmitted"
      >
        <label for="">Granularity*</label>
        <br />
        <p-dropdown
          [options]="granularities_list"
          placeholder="Select granularity"
          formControlName="granularity"
        ></p-dropdown>
      </div>
      <div [class.invalid]="!form.get('data_usage')?.valid && this.formSubmitted">
        <label for="">Data Usage*</label>
        <br />
        <p-dropdown
          [options]="data_usage_list"
          placeholder="Select data usage"
          formControlName="data_usage"
        ></p-dropdown>
      </div>
      <div [class.invalid]="!form.get('channel')?.valid && this.formSubmitted">
        <label for="">Channel/Supply Chain*</label>
        <br />
        <p-dropdown
          [options]="channels_list"
          placeholder="Select channel"
          formControlName="channel"
        ></p-dropdown>
      </div>
      <div>
        <label for="">Panel Coverage</label>
        <br />
        <p-inputNumber
          formControlName="panel_coverage"
          suffix="%"
        ></p-inputNumber>
      </div>
    </div>
    <h2>Product Classification</h2>
    <div class="details">
      <div>
        <label for="">Formulation Class Type</label>
        <br />
        <p-dropdown
          [options]="formulation_class_types"
          placeholder="Select formulation class type"
          formControlName="formulation_class_type"
        ></p-dropdown>
      </div>
      <div>
        <label for="">Therapy Class Type</label>
        <br />
        <p-dropdown
          [options]="therapy_class_types"
          placeholder="Select therapy class type"
          formControlName="therapy_class_type"
        ></p-dropdown>
      </div>
      <div>
        <label for="">Volume Units</label>
        <br />
        <p-multiSelect
          [options]="volume_units"
          placeholder="Select volume units"
          formControlName="volume_units"
        ></p-multiSelect>
      </div>
    </div>
    <h2>Price Level</h2>
    <div class="details">
      <div>
        <label for="">Price Level</label>
        <br />
        <p-dropdown
          [options]="price_levels"
          placeholder="Select price level"
          formControlName="price_level"
        ></p-dropdown>
      </div>
      <div>
        <label for="">Currency</label>
        <br />
        <p-dropdown
          [options]="currencies_list"
          placeholder="Select currency"
          formControlName="currency"
        ></p-dropdown>
      </div>
    </div>
    <h2>Time</h2>
    <div class="details">
      <div [class.invalid]=" !form.get('update_frequency')?.valid && this.formSubmitted " >
        <label for="">Update Frequency*</label>
        <br />
        <p-dropdown
          [options]="update_frequency_list"
          placeholder="Select update frequency"
          formControlName="update_frequency"
        ></p-dropdown>
      </div>
      <div>
        <label for="">Back Data by Update</label>
        <br />
        <p-inputNumber
          placeholder="37"
          formControlName="back_data_by_update"
          [suffix]="getBackDataUnit()"
        />
      </div>
      <div>
        <label for="">Start Period</label>
        <br />
        <p-calendar
          dateFormat="mm.yy"
          formControlName="start_date"
        ></p-calendar>
      </div>
      <div
        [class.invalid]="!form.get('end_period')?.valid && this.formSubmitted"
      >
        <label for="">End Period*</label>
        <br />
        <p-calendar
          dateFormat="mm.yy"
          formControlName="end_period"
        ></p-calendar>
      </div>
      <div class="two_columns">
        <label for="">Delivery Calendar</label>
        <div id="delivery_calendar_message">
          <input pInputText readonly="readonly" [placeholder]="deliveryCalendarMessage()" type="text"/>
          <img src="/assets/icons/calendar.svg" (click)="openRepeatDialog()">
        </div>
      </div>
    </div>
  </div>
</form>
