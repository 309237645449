<div id="dialog_wrapper">
    <p-confirmDialog key="confirm_password_change">
      <ng-template pTemplate="header">
        <div id="dialog_header">
          <h2>Change your password</h2>
        </div>
        <img src="assets/icons/exit.svg" (click)="closeDialog()" />
      </ng-template>
      <ng-template pTemplate="message">
          <div id="content" *ngIf="!this.isConfirmed">
              <div id="message">
                  <p>Press the button below to change your password. You will receive an email with the verification link.</p>
              </div>
          </div>
          <div id="content" *ngIf="this.isConfirmed">
              <div id="message">
                  <p>Check your Inbox for the verification email!</p>
              </div>
          </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button class="white_button" (onClick)="closeDialog()" *ngIf="!this.isConfirmed">Cancel</p-button>
        <p-button id="confirm_button" (onClick)="sendVerificationEmail()" *ngIf="!this.isConfirmed">
          Send verification link
        </p-button>
        <p-button id="confirm_button" (onClick)="closeDialog()" *ngIf="this.isConfirmed">
          Done
        </p-button>
      </ng-template>
    </p-confirmDialog>
  </div>
  
  