import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updatesGranularityFilter'
})
export class updatesGranularityFilterPipe implements PipeTransform {

  transform(updates: any [], selectedGranularities: string []): any [] {
    console.log(updates);
    if (selectedGranularities.length === 1 && selectedGranularities[0] === "all"){
      return updates;
    }

    console.log(updates);
    return updates.filter((update) => (update.data.some((source: any) => (source.granularity && selectedGranularities.includes(source.granularity)))));
  }

}
