import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {

  @Input() filters = [
    {name: "countries", class_name: "country_list", placeholder: "Geography", options: [] as any [], selected_values: [] as any []},
    {name: "data_types", class_name: "data_type_list", placeholder: "Data Type", options: [] as any [], selected_values: [] as any []},
    {name: "channels", class_name: "channel_list", placeholder: "Channel", options: [] as any [], selected_values: [] as any []},
    {name: "granularities", class_name: "granularity_list", placeholder: "Granularity", options: [] as any [], selected_values: [] as any []},
    {name: "frequencies", class_name: "frequency_list", placeholder: "Frequency", options: [] as any [], selected_values: [] as any []},
    {name: "update_statuses", class_name: "update_status_list", placeholder: "Update status", options: [] as any [], selected_values: [] as any []},
  ];

  @Output() filtersChanged: EventEmitter<any> = new EventEmitter();

  selectedFilterChanged(filter_name: string, event: any) {
    if (this.filtersChanged)
      this.filtersChanged.emit({name: filter_name, event});
  }
}
