export function buildChartData(data: any[], color = "#367CF6") {
  return {
    data: [...data],
    backgroundColor: color
  };
}

export function buildMultipleChartData(multiData: any[][]) {
    const colors = ["#367CF6", "#F59C4A"];
    return multiData.map((data: any [], index: number) => (buildChartData(data, colors[index%2])))
}
