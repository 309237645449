import { Pipe, PipeTransform } from "@angular/core";
import { BRMColumn, brmColumnType, brmFilterCriteria } from "../columns";
import { dataFilter } from "./filter";

@Pipe({
  name: "sorterFilter",
  pure: false,
})
export class SorterFilterPipe implements PipeTransform {
  transform(
    rows: any[],
    sorter: { column: BRMColumn; orderIsDesc: boolean } | undefined
  ): any[] {
    if (!sorter) return rows;

    const sort_function_number = (row1: any, row2: any) => {
      let res =
        (row1 as Record<string, any>)[sorter.column.getField()] >
        (row1 as Record<string, any>)[sorter.column.getField()]
          ? 1
          : -1;

      return res;
    };

    const sort_function_string = (row1: any, row2: any) => {
      return (
        (row1 as Record<string, any>)[sorter.column.getField()] as string
      ).localeCompare(
        (row2 as Record<string, any>)[sorter.column.getField()] as string
      );
    };
    let sort_function = sort_function_string;
    if (
      sorter.column.type === brmColumnType.number ||
      sorter.column.type === brmColumnType.dateTime
    )
      sort_function = sort_function_number;

    console.log("function is ", sort_function);
    rows.sort((A: any, B: any) =>
      flip_output(sort_function(A, B), sorter.orderIsDesc)
    );
    return rows;
  }
}

function flip_output(output: number, toFlip: boolean) {
  if (toFlip)
    switch (output) {
      case 1:
        return -1;
      case -1:
        return 1;
    }

  return output;
}
