import { Component, Input } from "@angular/core";
import { routeDetails } from "./routeDetails";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sub-menu",
  templateUrl: "./sub-menu.component.html",
  styleUrls: ["./sub-menu.component.scss"],
})
export class SubMenuComponent {
  appMap = [
    {
      name: "all_sources",
      displayName: "All Data Sources",
      link: "/",
      path: ["intelligence_center", "data_library"],
    },
    {
      name: "all_updates",
      displayName: null,
      link: null,
      path: ["intelligence_center", "update_center"],
    },
    {
      name: "pending_updates",
      displayName: null,
      link: null,
      path: ["intelligence_center", "update_center"],
    },
    {
      name: "in_progress_updates",
      displayName: null,
      link: null,
      path: ["intelligence_center", "update_center"],
    },
    {
      name: "completed_updates",
      displayName: null,
      link: null,
      path: ["intelligence_center", "update_center"],
    },
    {
      name: "source_details",
      displayName: null,
      path: ["intelligence_center", "data_library", "all_sources"],
      link: null
    },
    {
      name: "intelligence_center",
      displayName: "Intelligence Center",
      link: "/home"
    },
    {
      name: "data_library",
      displayName: "Data Library",
      link: null
    },
    {
      name: "update_center",
      displayName: "Update Center",
      link: "/updates/all"
    },
    {
      name: "quality_center_dashboard",
      displayName: "Quality Center",
      path: ["intelligence_center"],
      link: "/quality_center_dashboard"
    },
    {
      name: "data_center",
      displayName: "Data Center",
    },
    {
      name: "business_data",
      displayName: "Business Data",
      path: ["intelligence_center", "data_center"],
      link: "/business_data"
    },
    {
      name: "business_rules",
      displayName: "Business Rules",
      path: ["intelligence_center", "data_center"],
      link: "/business_rules"
    },
    {
      name: "system_data",
      displayName: "System Data",
      path: ["intelligence_center", "data_center"],
      link: "/system_data"
    },
  ] as routeDetails [];

  currentRouteName: string | undefined;

  @Input() displayName: string | undefined;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.currentRouteName = data["name"];
    });
  }

  findPartInformation(pathName: string) : routeDetails | undefined {
    return this.appMap.find((pathPart) => pathPart.name === pathName);
  }

  getLinks(): routeDetails[] {
    if (this.currentRouteName) {
      let currentRouteInformation = this.appMap.find((routeInformation) => routeInformation.name === this.currentRouteName);

      if (currentRouteInformation && currentRouteInformation.path) {
        let links = currentRouteInformation.path.map((pathPart) => (
          this.findPartInformation(pathPart)
        ));
        
        let currentRouteInfo = this.findPartInformation(this.currentRouteName);
        if (currentRouteInfo)
          {
            if (this.displayName)
              currentRouteInfo.displayName = this.displayName;

            links.push(currentRouteInfo);
          }

        return links as routeDetails [];
      }

      return []
    }

    return [];
  }
}
