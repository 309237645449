import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { AccountService } from "../core/services/account.service";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
})
export class OnboardingComponent implements OnInit {
  current_step = 0;
  steps = [
    {
      feature: "Data Library",
      feature_summary:
        "Your Ultimate Solution to Navigate All Your Data at a Glance",
      feature_description: `Unlock data precision at your fingertips with Data Library!
      Navigate effortlessly through the “All Data Sources” hub and curate your favourites for swift access.
      Elevate your data game with centralized control and personalized efficiency.`,
    },
    {
      feature: "Update Center",
      feature_summary:
        "All-in-One Platform to Fast Track Pharmaceutical Data Processing",
      feature_description: `Experience the future of data handling with Update Center! In this
        dynamic kitchen, automate the processing of multiple data sources
        seamlessly, elevating your data operations to unprecedented levels of
        efficiency`,
    },
    {
      feature: "Quality Control",
      feature_summary:
        "Secure the Quality of Your Data, to Elevate Your Business Decision",
      feature_description: `Your Hub for Data Mastery and Strategic Insights!
      From “Dashboard” for holistic views to “Data Analysis” for in-depth scrutiny,
      streamline your operations and elevate your data game with precision and clarity.`,
    },
  ];

  dont_show_again = false;
  dont_show_again_is_disabled = false;

  constructor(private router: Router, private accountService: AccountService) {}

  ngOnInit(): void {}

  skipOnboarding() {
    this.router.navigate(["/"]);
  }

  moveToNextStep() {
    if (this.current_step < 3) this.current_step++;
    else this.router.navigate(["/"]);
  }

  dont_show_again_selected(event: boolean) {
    if (!event) {
      this.dont_show_again_is_disabled = true;
      this.accountService.skipOnBoarding().subscribe((res) => undefined);
    }
  }
}
