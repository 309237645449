import { Pipe, PipeTransform } from "@angular/core";
import { TreeNode } from "primeng/api";

@Pipe({
  name: "KPIsStepsFilter",
  pure: true,
})
export class KPIsStepsFilter implements PipeTransform {
  transform(KPIs: TreeNode[], selected_step: string[]): any {
    const steps = KPIs.map((node: TreeNode) =>
      node.data["label"].toLowerCase()
    );
    if (selected_step.includes("all")) return KPIs;
    else {
      return KPIs.filter((node: TreeNode) =>
        selected_step.includes(node.data["label"].toLowerCase())
      );
    }
  }
}
