import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BRMServiceService {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  constructor(private http: HttpClient) {}

  getTableData(tableName: string) {
    let api = `${environment.apiUrl}/brm/get_rs_tables_content/${tableName}`;
    return this.http.get(api, { headers: this.headers }) as Observable<any[]>;
  }

  // Get table history
  getTableSchema(tableName: string) {
    let api = `${environment.apiUrl}/brm/get_table_schema/${tableName}`;
    return this.http.get<any>(api, { headers: this.headers });
  }

  // Get table history
  getTableHistory(tableName: string) {
    let api = `${environment.apiUrl}/brm/get_table_history/${tableName}`;
    return this.http.get<any>(api, { headers: this.headers });
  }

  // Get pending changes
  getPendingChanges() {
    let api = `${environment.apiUrl}/brm/get_pending_requests`;
    return this.http.get<any>(api, { headers: this.headers });
  }

  //Commit changes
  commitChanges(changes: any) {
    let api = `${environment.apiUrl}/brm/submit_changes/`;
    return this.http.post<any>(api, changes, { headers: this.headers });
  }

  //Approve Changes
  approveChanges(change_id: number) {
    let api = `${environment.apiUrl}/brm/approve_changes/${change_id}/`;
    return this.http.post<any>(api, { headers: this.headers });
  }

  //Reject Changes
  rejectChanges(change_id: number) {
    let api = `${environment.apiUrl}/brm/reject_changes/${change_id}/`;
    return this.http.post<any>(api, { headers: this.headers });
  }
}
