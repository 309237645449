import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { tablesByCategory } from "./tables";
import { BRMServiceService as BRMService } from "../core/services/brmservice.service";
import { ConfirmationService } from "primeng/api";
import {
  BooleanBRMColumn,
  BRMColumn,
  brmColumnType,
  brmFilterCriteria,
  DateTimeBRMColumn,
  ListBRMColumn,
  NumberBRMColumn,
  StringBRMColumn,
} from "./columns";
import { batchEdit, SingleEdit, singleEdit, UndoQueue } from "./undoQueue";
import { ApplyChanges } from "./pipes/apply-changes.pipe";
import { capitalizeFirstLetter } from "../core/models/helpers";
import { changedRules, sameObject } from "./pipes/change-filter.pipe";
import { zip } from "rxjs";
import { AccountService } from "../core/services/account.service";

@Component({
  selector: "app-brm",
  templateUrl: "./brm.component.html",
  styleUrls: ["./brm.component.scss"],
})
export class BRMComponent {
  batch_decision_mode: string | undefined;
  batchDecisionChangeIDs() {
    if (!this.data) return [];

    const changedRulesList = changedRules(this.pendingChanges, this.data);
    let toSubmit = [] as any[];
    changedRulesList.forEach((item: any) => {
      if (this.rowSelection[item.selection_id] === 1) {
        const keyValue = this.getPrimaryKeyValue(item, this.primary_key ?? []);
        const changes = this.pendingChanges.filter((change: any) =>
          this.sameKey(change.change_key, this.primary_key ?? [], keyValue)
        );
        const toAdd = changes.map(
          (change: any) => (change as Record<string, any>)["change_id"]
        );
        toSubmit = [...toSubmit, ...toAdd];
      }
    });
    return toSubmit;
  }

  decideBatch(mode: string) {
    this.batch_decision_mode = mode;
    const toSubmit = this.batchDecisionChangeIDs();
    console.log("to submit", toSubmit);
    this.loadBatchDecisionConfirmDialog();
  }

  batchCount() {
    if (!this.data) return 0;

    if (this.editingIsEnabled) return this.data.length;
    else if (this.approveChangesIsEnabled) return this.changed_rows_count;
    return undefined;
  }

  allSelection = false;

  step_options = [
    {
      label: "Steps",
      items: [],
    },
  ] as { label: string; items: any[] }[];

  table_options = [
    {
      label: "Tables",
      items: [],
    },
  ] as { label: string; items: any[] }[];

  headers = [] as { visible: boolean; column: BRMColumn; width: number }[];

  selected_step: string | undefined = "referencing";
  selected_table = "molecules";

  data: any[] | undefined;

  activity_log: any;

  showApprovals = false;
  showPending = true;

  columns_items = [] as any[];

  column = new StringBRMColumn("recode");

  currentCategoryTables = [];
  currentCategory: string | undefined;
  @ViewChild("dt") table: any;

  changes = {} as Record<string, any>;
  changeLog = new UndoQueue();
  pendingChanges = [] as { column_name: string }[];
  all_rows_selected = false;

  dirty_rules = new Set<object>();
  selected_rows_ids = [] as any[];
  selected_batch_edit_column =
    this.headers.length > 0 ? this.headers[0].column : undefined;

  formula_bar_new_value: string | undefined;

  editingIsEnabled = false;
  approveChangesIsEnabled = false;

  editableHeaders = [] as any[];
  primary_key: string[] | undefined;
  editing_cell_selected: object | undefined;
  editing_column_selected: BRMColumn | undefined;

  selected_cell_key = {};
  panelIsShown = false;

  filteringCriteria: Record<string, brmFilterCriteria> = {};
  sorter: { column: BRMColumn; orderIsDesc: boolean } | undefined;
  account_role: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private brmService: BRMService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.accountService.getMyAccount().subscribe((res) => {
      this.account_role = res["role"].toLowerCase();
    });

    this.currentCategory = this.route.snapshot.data["name"];
    if (this.currentCategory) {
      let config = this.loadBRMConfig();
      if (!config) {
        this.setupBRMConfig();
        config = this.loadBRMConfig();
      }

      const cat_config = config[this.currentCategory];
      this.loadSteps(cat_config["step"]);
      this.loadTables(cat_config["table_name"]);
    }
  }

  userIsAtLeastManager() {
    //@TODO fix this to consider super admins
    return this.account_role === "process_supervisor";
  }

  resetFormulaBar() {
    this.formula_bar_new_value = undefined;
  }

  updateBRMConfig(
    selected_category: string,
    selected_step: string,
    selected_table: string
  ) {
    let config = this.loadBRMConfig();
    if (!config) {
      this.setupBRMConfig();
      config = this.loadBRMConfig();
    }

    Object.keys(config).map((category: string) =>
      category === selected_category
        ? (config[category] = {
            step: selected_step,
            table_name: selected_table,
          })
        : undefined
    );

    localStorage.setItem("brm_config", JSON.stringify(config));
  }

  setupBRMConfig() {
    const config = {
      business_data: { step: "referencing", table_name: "rec_molecules" },
      business_rules: {
        step: "categorization",
        table_name: "cat_category_rule",
      },
      system_data: { step: "cleaning", table_name: "ecd_channel" },
    };
    localStorage.setItem("brm_config", JSON.stringify(config));
  }

  loadBRMConfig() {
    const config = localStorage.getItem("brm_config");
    if (!config) return undefined;

    return JSON.parse(config);
  }

  isStringHeader(header: { column: BRMColumn }) {
    return header.column.type === brmColumnType.string;
  }
  selectedColumnIsString() {
    if (this.editing_column_selected) {
      return this.editing_column_selected.type === brmColumnType.string;
    }
    return true;
  }

  getFormulaBarValue(changeLog: any) {
    if (
      this.primary_key &&
      this.editing_cell_selected &&
      this.editing_column_selected &&
      Object.keys(this.editing_cell_selected).length > 0
    ) {
      console.log(
        "found rule =",
        this.getRuleByPrimaryKey(this.editing_cell_selected)
      );
      let rule = this.getRuleByPrimaryKey(this.editing_cell_selected);
      rule = ApplyChanges(rule, changeLog, this.primary_key);
      return rule[this.editing_column_selected.getField()];
    }
    return "";
  }

  formulaBarIsEnabled() {
    return (
      this.primary_key &&
      this.editing_cell_selected &&
      this.editing_column_selected &&
      Object.keys(this.editing_cell_selected).length > 0 &&
      this.editing_column_selected.type === brmColumnType.string
    );
  }

  getRuleByPrimaryKey(primaryKeyValue: Record<string, any>) {
    if (!this.data) return {};

    const finalRule = this.data.find((rule: any) => {
      let primary_key = [] as string[];
      if (this.primary_key) {
        primary_key = this.primary_key;
      }
      console.log("is it rule = ", rule);
      const KeyValue = this.getPrimaryKeyValue(rule, primary_key);
      console.log("helloe", KeyValue);
      return this.sameKey(rule, primary_key, primaryKeyValue);
    });

    return finalRule;
  }

  sameKey(
    rule: any,
    primary_key: string[],
    primary_key_value: Record<string, any>
  ) {
    console.log("received key", primary_key_value);
    return primary_key
      .map((column: string) => rule[column] === primary_key_value[column])
      .every((value: boolean) => value);
  }

  getPrimaryKeyValue(rule: any, primary_key: string[]) {
    const final = {} as Record<string, any>;
    primary_key.map((column: string) => (final[column] = rule[column]));
    return final;
  }

  cellClicked(rule: any, column: BRMColumn) {
    if (this.primary_key) {
      const primay_key_value = {} as Record<string, any>;
      this.primary_key?.map(
        (column: string) => (primay_key_value[column] = rule[column])
      );
      this.editing_cell_selected = primay_key_value;
      this.editing_column_selected = column;
    }
  }
  displayCellHistory(isChanged: boolean, data: any) {
    console.log("called");
    if (isChanged && this.approveChangesIsEnabled) {
      this.showActivityLog();

      this.selected_cell_key = this.getRowKeyInformation(data);
    }
  }

  scrollToRow(event: number) {}
  show_activity_log = false;

  showActivityLog() {
    this.show_activity_log = true;
  }

  changesWindowClosed() {
    this.loadPendingChanges();
  }

  loadTableHistory() {
    this.brmService
      .getTableHistory(this.selected_table)
      .subscribe((res: any) => {
        this.activity_log = res;
      });
  }

  areObjectsEqual(objA: any, objB: any, keys_list: string[]) {
    return keys_list
      .map((key: string) => objA[key] === objB[key])
      .every((pre: boolean) => pre);
  }

  changed_rows_count = 0;
  loadPendingChanges() {
    if (!this.data) return;

    this.brmService.getPendingChanges().subscribe((res: any) => {
      this.pendingChanges = res.filter(
        (changeRequest: any) => changeRequest.table_name === this.selected_table
      );

      if (this.data)
        this.changed_rows_count = changedRules(
          this.pendingChanges,
          this.data
        ).length;
      console.log("pending changes = ", this.pendingChanges);
      console.log("count = ", this.changed_rows_count);
      console.log("data = ", this.data);
      /*this.data.map((rule: any) => {
        let primary_key = this.primary_key;
        const currentRuleKey = this.getPrimaryKeyValue(
          rule,
          this.primary_key ?? []
        );
        console.log("current rule ", this.primary_key);

        const relatedChanges = this.pendingChanges.filter((change: any) =>
          this.areObjectsEqual(
            currentRuleKey,
            change.change_key,
            this.primary_key ?? []
          )
        );

        console.log("related_changes: ", relatedChanges);
      });*/

      console.log("pending changes:", this.pendingChanges);
    });
  }

  allRowSelectionChanged($event: any) {
    if ($event.checked)
      this.rowSelection = this.rowSelection.map((state: number | undefined) =>
        state !== -1 ? 1 : -1
      );
    else
      this.rowSelection = this.rowSelection.map((state: number | undefined) =>
        state !== -1 ? 0 : -1
      );
  }

  numberOfSelectedRows() {
    return this.rowSelection.filter((state: number) => state === 1).length;
  }

  someRowsAreSelected() {
    return this.rowSelection.some((state: number | undefined) => state === 1);
  }

  selected_rules: any[] = [];

  rowSelectionChanged(rule: any, $event: any) {
    this.allSelection = this.rowSelection.every((state: any) => state);
  }

  undoLastChange() {
    const old = [...this.changeLog.getItems()];
    this.changeLog.dequeue();
    console.log("log: before: ", old, "new: ", this.changeLog.getItems());
  }

  formulaBarChange(event: any) {
    const newValue = event.target.value;
    this.formula_bar_new_value = newValue;
  }

  submitFormulaBarChange() {
    if (
      this.editing_cell_selected &&
      this.editing_column_selected &&
      this.primary_key
    ) {
      console.log("cell to edit is ", this.editing_cell_selected);
      const keyValue = this.getPrimaryKeyValue(
        this.editing_cell_selected,
        this.primary_key
      );

      this.newChange(
        new SingleEdit(
          keyValue,
          this.editing_column_selected.getField(),
          this.formula_bar_new_value,
          this.getFormulaBarValue(this.changeLog)
        )
      );
    }
  }

  newChange(event: singleEdit | batchEdit) {
    console.log("new change:", event);
    event.getIDs().map((n: object) => this.dirty_rules.add(n));
    console.log(this.dirty_rules);
    this.changeLog.enqueue(event);
  }

  getVisibleHeaders() {
    return this.headers.filter((header: any) => header.visible);
  }

  columnsReordered(event: any) {
    console.log(event);
    console.log(this.columns_items);
  }

  getTitle() {
    const titles = {
      business_data: "Business Data",
      business_rules: "Business Rules",
      system_data: "System Data",
    } as Record<string, string>;
    if (this.currentCategory) return titles[this.currentCategory];
    return "";
  }

  cancelEditing() {
    if (this.editingIsEnabled) {
      this.changeLog.empty();
      this.editingIsEnabled = false;
      this.rowSelection = [];
    } else if (this.approveChangesIsEnabled) {
      this.rowSelection = [];
      this.approveChangesIsEnabled = false;
    }
    this.allSelection = false;
  }

  openBatchEdit() {
    this.confirmationService.confirm({
      header: "Confirmation",
      key: "batch_edit_dialog",
      message: "Please confirm to proceed moving forward.",
      acceptIcon: "pi pi-check mr-2",
      rejectIcon: "pi pi-times mr-2",
      rejectButtonStyleClass: "p-button-sm",
      acceptButtonStyleClass: "p-button-outlined p-button-sm",
      accept: () => {
        alert("yes");
      },
      reject: () => {
        alert("yes");
      },
    });
  }

  jsonify(object: any) {
    return JSON.stringify(object);
  }

  openConfirmChanges() {
    if (this.selected_table)
      this.confirmationService.confirm({
        header: "Confirmation",
        key: "confirm_changes_dialog",
        message: "Please confirm to proceed moving forward.",
        acceptIcon: "pi pi-check mr-2",
        rejectIcon: "pi pi-times mr-2",
        rejectButtonStyleClass: "p-button-sm",
        acceptButtonStyleClass: "p-button-outlined p-button-sm",
        accept: () => {
          alert("yes");
        },
        reject: () => {
          alert("yes");
        },
      });
  }

  emptyChangeLog() {
    this.changeLog.empty();
  }

  addRow() {
    if (!this.data) return;

    this.data.push({});
    const body =
      this.table.containerViewChild.nativeElement.getElementsByClassName(
        "ui-table-scrollable-body"
      )[0];
    body.scrollTop = body.scrollHeight;
  }

  enableApproveChangesMode() {
    this.approveChangesIsEnabled = true;
    this.setupSelectionArray("approve_changes");
  }

  enableEditing() {
    if (this.currentTableMode() === "edit") {
      this.editingIsEnabled = true;
      this.setupSelectionArray("edit_mode");
    }
  }

  disableEditing() {
    if (this.currentTableMode() === "edit") this.editingIsEnabled = false;
  }

  getIconPath(step: string | undefined) {
    if (!step) return "";

    return step;
  }

  loadSteps(selected_step: string | undefined = undefined) {
    if (this.currentCategory) {
      const steps = Object.keys(tablesByCategory[this.currentCategory]);
      console.log(steps);
      this.step_options = [
        {
          label: "Steps",
          items: steps.map((step: any) => ({
            value: step,
            label: capitalizeFirstLetter(step),
          })),
        },
      ];
    }
    if (selected_step) {
      this.selected_step = selected_step;
    } else this.setFirstStepSelected();
  }

  ruleIsChanged(rule: any, changedRules: any[]) {
    const foundRule = changedRules.find((changedRule: any) =>
      sameObject(rule, changedRule)
    );
    return foundRule;
  }

  setupSelectionArray(mode: string) {
    if (!this.data) return;

    if (mode === "approve_changes") {
      const newRowSelection = [] as number[];
      const changed = changedRules(this.pendingChanges, this.data);
      this.data.map((rule: any) => {
        const res = this.ruleIsChanged(rule, changed);
        if (res) {
          console.log("changed rule here");
          newRowSelection[res.selection_id] = 0;
        } else newRowSelection[rule.selection_id] = -1;
      });

      console.log("approve changes selection array is ", newRowSelection);

      this.rowSelection = [...newRowSelection];
    } else {
      const newRowSelection = [] as number[];
      this.data.map((rule: any) => (newRowSelection[rule.selection_id] = 0));

      console.log("edit selection array is ", newRowSelection);

      this.rowSelection = [...newRowSelection];
    }
  }

  selectedTableChanged() {
    let selected_table = this.selected_table;
    let original_table: string | undefined;
    if (
      selected_table === "preview_function" ||
      selected_table === "field_properties"
    ) {
      original_table = selected_table;
      selected_table = "tables_schema";
    }

    this.loadTable(selected_table).subscribe((res: any) => {
      res = res.data;
      console.log(res);

      if (this.currentCategory && this.selected_step) {
        this.updateBRMConfig(
          this.currentCategory,
          this.selected_step,
          selected_table
        );
      }

      let data = res;

      this.filteringCriteria = {};
      if (
        original_table === "preview_function" ||
        original_table === "field_properties"
      ) {
        data = this.formatSchemaTable(data, original_table);
        console.log("new_data = ", data);
      }

      if (selected_table !== "tables_schema")
        this.brmService.getTableSchema(this.selected_table).subscribe((res) => {
          this.table_schema = res;
          this.primary_key = this.table_schema.schema
            .filter((key: any) => key.primary)
            .map((key: any) => key.name);

          this.headers = this.computeHeaders();
          this.editableHeaders = this.headers
            .filter((header: any) => header.column.isEditable)
            .map((header: any) => ({
              value: header.column,
              label: header.column.getHeader(),
            }));

          const listColumns = this.headers
            .filter((header: any) => header.column.type === brmColumnType.list)
            .map((header: any) => header.column);

          console.log("list columns ", listColumns);
          const newData = [] as any[];
          data.map((rule: any) => {
            listColumns.map((column: BRMColumn) => {
              rule[column.field] = rule[column.field].toLowerCase();
            });
            console.log("rule now is ", this.jsonify(rule));
            newData.push(rule);
          });
          console.log("new_data", newData);

          const formattedData = [] as any[];
          let i = 0;
          data = newData
            .map((rule: any) => {
              console.log("i = ", i);
              console.log("current rule is ", rule);
              console.log("new rule is", { selection_id: i, ...rule });
              const newRule = { ...rule, selection_id: i };
              i++;
              return newRule;
            })
            .map((data_point: any) => {
              const current_data_point = data_point;
              //Convert timestamps to date

              if (Object.keys(current_data_point).includes("data_upload")) {
                current_data_point["data_upload"] = new Date(
                  current_data_point["data_upload"]
                );
              }

              const originals = { ...current_data_point };
              Object.keys(originals).map((key: any) => {
                return (current_data_point["original_" + key] =
                  current_data_point[key]);
              });
              return { ...current_data_point, ...originals };
            });
          console.log("early_data", data);
          this.data = [...data];
          console.log("first = data", this.data);
          this.loadPendingChanges();
          this.loadTableHistory();
        });
    });
  }

  rowSelection: number[] = [];

  loadBatchDecisionConfirmDialog() {
    this.confirmationService.confirm({
      header: "Confirmation",
      key: "confirm_batch_decision_dialog",
      message: "Please confirm to proceed moving forward.",
      acceptIcon: "pi pi-check mr-2",
      rejectIcon: "pi pi-times mr-2",
      rejectButtonStyleClass: "p-button-sm",
      acceptButtonStyleClass: "p-button-outlined p-button-sm",
      accept: () => {
        alert("yes");
      },
      reject: () => {
        alert("yes");
      },
    });
  }
  formatSchemaTable(data: any[], mode: string) {
    console.log("data =", data);
    console.log("mode =", mode);

    if (mode === "preview_function") {
      return data.map((data_point: any) => {
        return {
          "Table Business Name": data_point["original_business_name"],
          "Table Technical Name": data_point["original_technical_name"],
          "Preview Function": data_point["original_editable"]
            ? "Edit mode"
            : "View mode",
          "Insert Function": data_point["original_insertable"]
            ? "Available"
            : "Not Available",
        };
      });
    } else {
      return data
        .map((data_point: any) => {
          const schema = JSON.parse(data_point.schema);
          return schema.map((column: any) => ({
            table_business_name: data_point["business_name"],
            table_technical_name: data_point["technical_name"],
            field: column["name"],
            property: column["primary"] ? "Primary Key" : "-",
            DataType: this.capitalizeDataType(column["type"]),
            length: column["length"],
            width: column["width"],
            editable_option: column["editable"] ? "Yes" : "No",
            presets_values:
              column["presets"].length > 0
                ? column["presets"].join(" , ")
                : "-",
          }));
        })
        .flat();
    }
  }

  capitalizeDataType(data_type: string) {
    return data_type === "timestamp"
      ? "TimeStamp"
      : data_type.charAt(0).toUpperCase() + data_type.slice(1);
  }

  table_schema: any | undefined = undefined;
  getRowKeyInformation(rule: any) {
    if (!this.primary_key) return "";
    console.log("I have primary key", this.primary_key);

    const final_key = {} as any;
    this.primary_key.map(
      (column: string) => (final_key[column] = rule[column])
    );
    return final_key;
  }

  equalKeys(changeKey: any, rule: any) {
    for (const [key] of Object.entries(changeKey)) {
      if (rule[key] !== changeKey[key]) return false;
    }

    return true;
  }

  columnIsChanged(rule: any, columnName: string) {
    if (!this.primary_key) return false;

    const pendingChange = this.pendingChanges.filter((change: any) =>
      this.equalKeys(change.change_key, rule)
    );

    if (pendingChange.length > 0) {
      return pendingChange[0].column_name === columnName;
    }

    return false;
  }

  loadTable(selected_table: string) {
    return this.brmService.getTableData(selected_table);
  }

  computeHeaders() {
    console.log("schema :", this.table_schema);
    return this.table_schema.schema
      .map((column: Record<string, any>) => {
        let width = 100;
        if (column["width"]) width = column["width"];

        let final_column;
        switch (column["type"]) {
          //@TODO fix boolean labels
          case "boolean":
            final_column = new BooleanBRMColumn(
              column["name"],
              "Is Checked",
              "Not Checked"
            );
            break;
          case "list":
            final_column = new ListBRMColumn(column["name"], column["presets"]);
            break;
          case "timestamp":
            final_column = new DateTimeBRMColumn(column["name"]);
            break;
          case "int":
            final_column = new NumberBRMColumn(column["name"]);
            break;
          default:
            final_column = new StringBRMColumn(column["name"]);
            break;
        }
        final_column.isEditable = column["editable"];
        return {
          column: final_column,
          visible: true,
          width: width,
        };
      })

      .filter(
        (column: any) => !column.column.getField().startsWith("original")
      );
  }

  batchEditColumnSelected() {
    this.openBatchEdit();
  }

  loadTables(selected_table: string | undefined = undefined) {
    if (this.currentCategory && this.selected_step) {
      this.currentCategoryTables =
        tablesByCategory[this.currentCategory][this.selected_step].tables;
      this.table_options = this.buildTableOptions();
      console.log(this.table_options);
      if (selected_table) {
        this.selected_table = selected_table;
      } else {
        selected_table = this.table_options[0].items[0].value;
        this.setFirstTableSelected();
      }
      if (this.selected_table) this.selectedTableChanged();
    }
  }

  setFirstTableSelected() {
    this.selected_table = this.table_options[0].items[0].value;
  }

  setFirstStepSelected() {
    this.selected_step = this.step_options[0].items[0].value;
  }

  buildTableOptions() {
    return [
      {
        label: "tables",
        items: this.currentCategoryTables.map((table: any) => ({
          label: table.displayName,
          value: table.backendName,
          mode: table.mode,
        })),
      },
    ];
  }

  showPanel() {
    this.panelIsShown = true;
  }

  hidePanel() {
    this.panelIsShown = false;
  }

  currentStepLabel() {
    const step = this.step_options[0].items.find(
      (step: any) => step.value === this.selected_step
    );
    if (step) return step.label;
    return "";
  }

  currentTableLabel() {
    const table = this.table_options[0].items.find(
      (table: any) => table.value === this.selected_table
    );
    if (table) return table.label;
    return "";
  }

  currentTableMode() {
    const table = this.table_options[0].items.find(
      (table: any) => table.value === this.selected_table
    );
    if (table) return table.mode;
    return "edit";
  }

  sorterChanged(event: any, column: BRMColumn) {
    this.sorter = { column: column, orderIsDesc: event === "dsc" };
  }

  filterChanged(event: any, field: string) {
    this.filteringCriteria[field] = event;
    console.log(this.filteringCriteria);
  }

  filterReset(field: string) {
    delete this.filteringCriteria[field];
  }
}
