import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sourcesCountryFilter'
})
export class SourcesCountryFilterPipe implements PipeTransform {

  transform(sources: any [], selectedCountries: string []): any [] {
    if (selectedCountries.length === 1 && selectedCountries[0] === "all"){
      return sources
    }

    return sources.filter((source) => (source.country_code && selectedCountries.includes(source.country_code)));
  }

}
