export function replaceAll(
  originalString: string,
  find: string,
  replacement: string
) {
  return originalString.split(find).join(replacement);
}

export const steps_ordering = [
  "cleaning",
  "consolidation",
  "referencing",
  "recoding",
  "categorization",
  "transcoding",
];

export function reOrderSummary(home_summary: any[]): any[] {
  const result = [];
  for (const step of steps_ordering) {
    const item = home_summary.find(
      (item) => item.batch_step.toLowerCase() === step
    );
    if (item) result.push(item);
  }

  return result;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function arrayIsAll(array: string[]) {
  return array.length === 1 && array[0] === "all";
}

export function arrayDifference(array1: string[], array2: string[]): string[] {
  return array1.filter((item: string) => !array2.includes(item));
}

export function removeAll(array: string[]) {
  return array.filter((item: string) => item !== "all");
}

export const delays_chart_options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      ticks: {
        padding: 14,
      },
      grid: {
        display: false,
        drawTicks: false,
        ticks: {
          color: "#8E9397",
          font: {
            size: 12,
            family: "Satoshi",
          },
        },
      },
    },
    y: {
      suggestedMin: 0,
      suggestedMax: 6,
      border: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        color: "#8E9397",
        crossAlign: "far",
        font: {
          size: 12,
          family: "Satoshi",
        },
        callback: function (value: any) {
          return parseInt(value) + " days";
        },
      },
    },
  },
};
