import { Component } from '@angular/core';

@Component({
  selector: 'supported-formats',
  templateUrl: './supported-formats.component.html',
  styleUrls: ['./supported-formats.component.scss']
})
export class SupportedFormatsComponent {

}
