<div id="filters_wrapper">
  <p-multiSelect
    *ngFor="let filter of this.filters"
    [options]="filter.options"
    [placeholder]="filter.placeholder"
    (onChange)="selectedFilterChanged(filter.name, $event)"
    [(ngModel)]="filter.selected_values"
    class="borderless without_border {{filter.class_name}}"
  >
    <ng-template let-value pTemplate="selectedItems">
      <div>{{ filter.placeholder }}</div>
    </ng-template>
  </p-multiSelect>
</div>
