<div id="dialog_wrapper">
  <p-confirmDialog key="confirm_changes_dialog">
    <ng-template pTemplate="header">
      <div>
        <h2>Commit Changes</h2>
        <h3>Confirm and save the changes that you've made.</h3>
      </div>
      <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
      <div id="content" *ngIf="!this.isConfirmed">
        <img src="assets/icons/brm_records.svg" alt="" />
        <div id="message">
          <h1>{{ this.numberOfRecords() }} Records</h1>
          <p>Changed in {{ this.tableName }} table</p>
        </div>
      </div>

      <div style="margin-top: 24px">
        <div id="log_header">
          <p>Review Changes</p>
          <p class="see_more_button" (click)="this.toggleActivityLimit()">{{this.seeMoreCaption()}}</p>
        </div>
        <div id="activity_history_container">
          <app-brm-activity-history
            [activities]="this.getChangeActivity()"
            [displayApproveRejection]="false"
            [activity_display_limit]="this.activity_display_limit"
          ></app-brm-activity-history>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        class="white_button"
        (onClick)="closeDialog()"
        *ngIf="!this.isConfirmed"
        >Cancel</p-button
      >
      <p-button
        id="confirm_button"
        (onClick)="confirmChanges()"
        *ngIf="!this.isConfirmed"
      >
        Commit
      </p-button>
      <p-button
        id="confirm_button"
        (onClick)="closeDialog()"
        *ngIf="this.isConfirmed"
      >
        Done
      </p-button>
    </ng-template>
  </p-confirmDialog>
</div>
