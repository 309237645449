<div id="activity_log_container">
  <div class="activity_log_header">
    <div>
      <h1>Activity Log</h1>
      <h2 style="display: none">Row #{{ this.row_number }}</h2>
    </div>
    <img
      src="assets/icons/exit.svg"
      (click)="this.activityLogClosed()"
      class="clickable"
    />
  </div>
  <app-brm-activity-history
    [activities]="this.activities"
    [selected_cell_key]="this.selected_cell_key"
    [height]="500"
    [userAtLeastManager]="this.userAtLeastManager"
  ></app-brm-activity-history>
</div>
