import {Pipe, PipeTransform} from "@angular/core";
import {brmFilterCriteria} from "../columns";
import {dataFilter} from "./filter";

@Pipe({
    name: "rowFilter",
    pure: false,
})
export class RowFilterPipe implements PipeTransform {
    transform(rows: any[], criteria: Record<string, brmFilterCriteria>): any[] {
        if (Object.values(criteria).length === 0)
            return rows;
        return dataFilter(rows, criteria);
    }
}
