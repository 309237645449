import { DragStartDelay } from "@angular/cdk/drag-drop";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { ToastService } from "src/app/core/services/toast.service";

@Component({
  selector: "upload-control",
  templateUrl: "./upload-component.component.html",
  styleUrls: ["./upload-component.component.scss"],
  providers: [MessageService],
})
export class UploadComponentComponent {
  @Output() filesChanged = new EventEmitter<File | null>();
  @Input() mode: string = "data";
  @ViewChild("fileUpload") fileUpload: any;

  allowed_mime_types = ["application/vnd.ms-excel", "text/csv", "text/plain"];
  maxSize = 3 * 1024 * 1024 * 1024;

  selectedFile: File | null = null;

  is_error = false;

  constructor(
    private messageService: MessageService,
    private toastService: ToastService
  ) {}

  newFileSelection(files: File[]) {
    const selection = files[0];

    if (selection.size > this.maxSize) {
      this.toastService.addToast({
        severity: "error",
        summary: "Error",
        detail:
          "File exceed the size limitation of 3GB.  Please upload a smaller file, or <a href='https://mangabey.io/' target='_blank'>contact us.</a>",
      });

      this.is_error = true;
    }

    this.selectedFile = files[0];
    this.filesChanged.emit(files[0]);
  }

  getUploadLabel() {
    if (this.mode === "data") return "Click to Upload";
    else return "Click to upload a new picture";
  }

  getSizeNotice() {
    if (this.mode === "data") return "Max. File Size: 3GB";
    else return "SVG, PNG, JPG (max. 800x800px)";
  }

  fileDropped(event: DragEvent) {
    event.preventDefault();

    if (
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    ) {
      const droppedFile = event.dataTransfer.files[0];

      //Ignore files with wrong formats
      if (!this.allowed_mime_types.includes(droppedFile.type)) return;

      this.selectedFile = droppedFile;

      if (this.selectedFile.size > this.maxSize) {
        this.toastService.addToast({
          severity: "error",
          summary: "Error",
          detail:
            "Selected file exceeded maximum allowed size: please contact support!",
        });

        this.is_error = true;
      }
    }
  }

  fileDragged(event: any) {
    event.preventDefault();
  }

  FileDragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  displayUploadDialog() {
    this.fileUpload.choose();
  }

  deleteFile() {
    this.fileUpload.clear();
    this.is_error = false;
    this.selectedFile = null;
    this.filesChanged.emit(null);
  }
}
