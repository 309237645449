import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export const MINE_DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MineDropdownComponent),
  multi: true,
};

export interface MineDropdownValue {
  is_other: boolean;
  value: string;
}

@Component({
  selector: "app-mine-dropdown",
  templateUrl: "./mine-dropdown.component.html",
  styleUrls: ["./mine-dropdown.component.scss"],
  providers: [MINE_DROPDOWN_VALUE_ACCESSOR],
})
export class MineDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() options: any[] = [
    {
      label: "Spain",
      value: "spain",
    },
    {
      label: "France",
      value: "france",
    },
  ];
  internal_value: any;
  onChange: any;
  onTouched: any;
  isDisabled: any;
  other_value = "";

  selected_value = "";

  ngOnInit(): void {
    this.options = [...this.options, { label: "Other", value: "other" }];
  }

  get value() {
    return this.internal_value;
  }

  set value(val) {
    this.internal_value = val;
  }

  writeValue(obj: any): void {
    this.internal_value = obj;
  }

  registerOnChange(fn: any): void {
    console.log("on change: registered ", fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  inputChanged(event: any) {
    console.log('new value', event.value[0]);
    console.log(this.selected_value);
    this.onChange({ is_other: false, value: event.value });
    console.log("on change emitted");
  }

  otherChanged(event: any) {
    this.onChange({ is_other: true, value: event.target.value });
    console.log("on change emitted ", event.target.value);
  }

  blur() {
    this.onTouched();
  }
}
