import {
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  SourceStateStringToEnum,
} from "../shared/source-card/source-card.component";
import { SourceService } from "../core/services/source.service";
import { Source } from "../core/models/source";
import { getCountryName } from "../core/models/country_codes";
import {
  channel_list,
  data_type_list,
  getCountries,
  granularity_list,
  update_frequency_list,
} from "../landing/my/filters-data";
import { convertUTCToLocal } from "../core/utils/dateTime";
import { SourcesStatusFilter } from "./pipes/status-filter.pipe";
import { sourceCriteria } from "./sourceCriteria";
import { ActivatedRoute } from "@angular/router";
import { OverlayPanel } from "primeng/overlaypanel";
import { PrimeNGConfig } from "primeng/api";
import { arrayDifference, arrayIsAll, removeAll } from "../core/models/helpers";

@Component({
  selector: "app-new-list",
  templateUrl: "./new-list.component.html",
  styleUrls: ["./new-list.component.scss"],
  providers: [SourcesStatusFilter],
})
export class NewListComponent {
  sources: any[] = [];
  sourcesToDisplay: any[] = [];

  countries: any[] = [];
  data_types: any[] = [];
  channels: any[] = [];
  granularities: any[] = [];
  frequencies: any[] = [];
  data_status_options = [
    {
      value: "",
      label: "FILTER BY",
      items: [
        { value: "created", label: "Created" },
        { value: "activated", label: "Activated" },
        { value: "deactivated", label: "Deactivated" },
        { value: "awaiting_delivery", label: "Awaiting Delivery" },
        { value: "delayed", label: "Delayed" },
        { value: "pending", label: "Pending" },
        { value: "in_progress", label: "In Progress" },
        { value: "completed", label: "Completed" },
      ],
    },
    {
      value: "",
      label: "SORT BY",
      items: [
        { value: "last_updated", label: "Last Updated" },
        { value: "last_modified", label: "Last Modified" },
        { value: "last_created", label: "Last Created" },
      ],
    },
  ];
  update_statuses: any[] = [
    { value: "last_updated", label: "Last Updated" },
    { value: "last_modified", label: "Last Modified" },
    { value: "last_created", label: "Last Created" },
  ];

  filters = {
    countries: {
      name: "countries",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Geography",
      options: [] as any[],
    },
    data_types: {
      name: "data_types",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Data Type",
      options: [] as any[],
    },
    channels: {
      name: "channels",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Channel",
      options: [] as any[],
    },
    granularities: {
      name: "granulartity",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Granularity",
      options: [] as any[],
    },
    frequencies: {
      name: "frequencies",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Frequency",
      options: [] as any[],
    },
  } as Record<string, any>;

  selected_update_status: string[] = ["last_created"];
  search_text = "";
  default_filter: string | null = null;

  constructor(
    private sourceService: SourceService,
    private route: ActivatedRoute,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit() {
    this.loadInformation();

    this.sourceService.sourceUpdated.subscribe(() => {
      this.loadInformation();
    });

    this.route.paramMap.subscribe((params) => {
      this.default_filter = params.get("default_filter");
      if (this.default_filter)
        this.selected_update_status.push(this.default_filter);
    });

    this.primengConfig.overlayOptions = {
    };
  }

  getCurrentStatusFilterLabel() {
    if (!this.selected_update_status) return undefined;

    const options = this.data_status_options[0].items;
    const currentOption = options.filter((option: any) =>
      this.selected_update_status.includes(option.value)
    );
    console.log("selected option: ", this.selected_update_status);
    console.log("all options: ", options);
    console.log("found options: ", currentOption);
    if (currentOption.length > 0)
      return currentOption
        .map((selected_option: any) => this.capitalize(selected_option.label))
        .join(" | ");
    else return undefined;
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  all_vs_30 = "all";

  @ViewChildren("item_overlay") el_overlay:
    | QueryList<ElementRef<OverlayPanel>>
    | undefined;

  displayOverlay(event: any, value: string) {
    console.log("this is the overlay I have, :", this.el_overlay?.toArray());
    if (this.el_overlay) {
      const currentItemOverlay = this.el_overlay
        .toArray()
        .filter(
          (el: any) =>
            el.el.nativeElement.attributes.getNamedItem("value").value === value
        )[0];
      console.log("overlay found:", currentItemOverlay);
      (currentItemOverlay as unknown as OverlayPanel).show(event);
    }
  }

  hideOverlay(value: string) {
    console.log("this is the overlay I have, :", this.el_overlay?.toArray());
    if (this.el_overlay) {
      const currentItemOverlay = this.el_overlay
        .toArray()
        .filter(
          (el: any) =>
            el.el.nativeElement.attributes.getNamedItem("value").value === value
        )[0];
      console.log("overlay found:", currentItemOverlay);
      (currentItemOverlay as unknown as OverlayPanel).hide();
    }
  }

  disabledIfNotInArray(initialArray: any[], filterArray: any[]) {
    return initialArray.map((item: any) =>
      filterArray.findIndex((value) => value === item.value) === -1
        ? { ...item, disabled: true }
        : { ...item, disabled: false }
    );
  }

  private loadInformation() {
    this.fetchContexts();
    this.sourceService.getSourceFilters().subscribe((res) => {
      this.countries = [
        { label: "All", value: "all" },
        ...getCountries(res.countries),
      ];

      this.filters["countries"].options = [
        { label: "All", value: "all" },
        ...getCountries(res.countries),
      ];

      this.data_types = [
        { label: "All", value: "all", disabled: false },
        ...this.disabledIfNotInArray(data_type_list, res.data_types),
      ];

      this.filters["data_types"].options = [
        { label: "All", value: "all" },
        ...this.disabledIfNotInArray(data_type_list, res.data_types),
      ];

      this.channels = [
        { label: "All", value: "all", disabled: false },
        ...this.disabledIfNotInArray(channel_list, res.channels),
      ];

      this.filters["channels"].options = [
        { label: "All", value: "all" },
        ...this.disabledIfNotInArray(channel_list, res.data_types),
      ];

      this.granularities = [
        { label: "All", value: "all", disabled: false },
        ...this.disabledIfNotInArray(granularity_list, res.granularities),
      ];

      this.filters["granularities"].options = [
        { label: "All", value: "all" },
        ...this.disabledIfNotInArray(granularity_list, res.data_types),
      ];

      this.frequencies = [
        { label: "All", value: "all", disabled: false },
        ...this.disabledIfNotInArray(
          update_frequency_list,
          res.update_frequencies
        ),
      ];

      this.filters["frequencies"].options = [
        { label: "All", value: "all" },
        ...this.disabledIfNotInArray(
          update_frequency_list,
          res.update_frequencies
        ),
      ];
    });
  }

  packCriteria(): sourceCriteria {
    const filters = this.selected_update_status.filter(
      (filter: string) => !this.sorters_list.includes(filter)
    );

    const sorter = this.selected_update_status.filter(
      (filter: string) => !this.sorters_list.includes(filter)
    );

    return {
      filters: filters,
      sorter: sorter[0],
    };
  }

  filterComponentValues() {
    return Object.values(this.filters);
  }

  old_sorters = ["last_created"] as string [];
  updateStatusChanged(event: any) {
    const newValues = event.value;
    const newSorters = newValues.filter((value: string) =>
      this.sorters_list.includes(value)
    );
    console.log("new values:", newValues);
    console.log("new sorters:", newSorters);
    this.handleSorters(newSorters);
  }

  sorters_list = ["last_created", "last_updated", "last_modified"];
  handleSorters(sorters: string[]) {
    if (sorters.length === 0) {
      this.selected_update_status = [
        ...new Set([...this.selected_update_status, "last_created"]),
      ];
      this.old_sorters = ["last_created"];
    }
    if (sorters.length > 1) {
      let newSorter = arrayDifference(sorters, this.old_sorters);
      this.old_sorters = newSorter;
      console.log("new sorting ", newSorter);
      console.log("new old sorters ", this.old_sorters);
      if (newSorter.length > 0) {
        this.selected_update_status = this.selected_update_status.filter(
          (update_status: string) => !this.sorters_list.includes(update_status)
        );
        this.selected_update_status = [
          ...this.selected_update_status,
          newSorter[0],
        ];
      }
    }

    console.log("new selected: ", this.selected_update_status);
  }

  selectedFilterChanged(changeEvent: any) {
    let filterName = changeEvent.name;
    let event = changeEvent.event;

    if (event.value.length === 0) {
      this.filters[filterName].selected_values = ["all"];
      return;
    }

    const difference = arrayDifference(
      event.value,
      this.filters[filterName].old_selected_values
    );

    if (arrayIsAll(difference))
      this.filters[filterName].selected_values = ["all"];
    else if (arrayIsAll(this.filters[filterName].old_selected_values))
      this.filters[filterName].selected_values = difference;
    else
      this.filters[filterName].selected_values = removeAll(
        this.filters[filterName].selected_values
      );

    this.filters[filterName].old_selected_values =
      this.filters[filterName].selected_values;
  }

  fetchContexts() {
    this.sourceService.getMySource().subscribe(
      (res) => {
        this.sources = res.map((source: Source) => ({
          id: source.id,
          created_at: source.created_at
            ? convertUTCToLocal(new Date(source.created_at))
            : null,
          modified_at: source.updated_at
            ? convertUTCToLocal(new Date(source.updated_at))
            : null,
          updated_at: source.last_update_date
            ? convertUTCToLocal(new Date(source.last_update_date))
            : null,
          is_activated: source.activated,
          panel_name: source.panel ? source.panel : "",
          state: source.state ? SourceStateStringToEnum[source.state] : "",
          quality: source.panel_coverage ? source.panel_coverage : "",
          end_period: convertUTCToLocal(new Date(source.end_period ?? "")),
          next_update: source.next_update
            ? convertUTCToLocal(new Date(source.next_update))
            : null,
          person_name: source.contact_owner_name
            ? source.contact_owner_name
            : "",
          country_code: source.country ? source.country : "",
          data_vendor: source.data_vendor ? source.data_vendor : "",
          data_type: source.data_type ? source.data_type : "",
          granularity: source.granularity ? source.granularity : "",
          update_frequency: source.update_frequency
            ? source.update_frequency
            : "",
          channel: source.channel ? source.channel : "",
        }));

        console.log(this.sources);
        this.sourcesToDisplay = this.sources;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  searchQueryChanged(event: Event) {
    const newValue = (event.target as HTMLInputElement).value
      .trim()
      .toLocaleLowerCase();
    if (newValue === "") this.sourcesToDisplay = this.sources;
    else
      this.sourcesToDisplay = this.sources.filter((source) =>
        (getCountryName(source.country_code) + "-" + source.panel_name)
          .toLocaleLowerCase()
          .includes(newValue)
      );
  }

  resetAllFilters() {
    this.filters["countries"].selected_values = this.filters[
      "countries"
    ].old_selected_values = ["all"];
    this.filters["data_types"].selected_values = this.filters[
      "data_types"
    ].old_selected_values = ["all"];
    this.filters["channels"].selected_values = this.filters[
      "channels"
    ].old_selected_values = ["all"];
    this.filters["granularities"].selected_values = this.filters[
      "granularities"
    ].old_selected_values = ["all"];
    this.filters["frequencies"].selected_values = this.filters[
      "frequencies"
    ].old_selected_values = ["all"];
  }
}
 