import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        console.log(req)
        if (req.headers.get("skip")|| req.url=='https://aka.ms/CaptureViewsReportEmbedConfig' 
        || req.url == 'https://app.powerbi.com/reportEmbed?reportId=8e6e4db2-209b-4a2e-99f6-b21aea5f4fa4&autoAuth=true&ctid=4ebd1f27-a38b-411a-8ce5-f9b764012688'
        || new URL (req.url).hostname.includes("azure")){
            console.log('found skip')
            return next.handle(req);
        }
        else {
            if (req.url.includes("signin"))
                return next.handle(req);
            console.log("URL = ", req.url)
                console.log('not found skip')
            const authToken = this.authService.getToken();
        req = req.clone({
            setHeaders: {
                Authorization: "Token " + authToken
            }
        });
        return next.handle(req);
        }
    }
}