import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import {
  stepInformation,
  stepsInformation,
  stepState,
} from "./stepsInformation";
import { stages } from "src/app/home-component/stages";

//@TODO remove when API starts returning this
const duration = 50;

@Component({
  selector: "app-steps-component",
  templateUrl: "./steps-component.component.html",
  styleUrls: ["./steps-component.component.scss"],
})
export class StepsComponentComponent implements OnChanges {
  currentSteps = Array(7);
  stepState = stepState;

  @Input() currentStep: string | undefined;
  @Output() currentStepChange = new EventEmitter();

  @Input() isClickable = false;

  @Input() stepsInfo: stepsInformation | undefined = {
    collection: { duration: duration, state: stepState.completed },
    cleaning: { duration: duration, state: stepState.completed },
    consolidation: { duration: duration, state: stepState.completed },
    referencing: { duration: duration, state: stepState.completed },
    recoding: { duration: duration, state: stepState.completed },
    categorization: { duration: duration, state: stepState.completed },
    transcoding: { duration: duration, state: stepState.completed },
  };
  displaySteps: any;

  ngOnInit() {}

  ngOnChanges() {
    this.displaySteps = this.stepsDisplay();
  }

  allStepsSelected() {
    return this.currentStep === "all_steps";
  }

  stepsDisplay() {
    console.log(this.currentStep);
    if (!this.stepsInfo) return [];
    console.log(this.stepsInfo);
    let displaySteps = [] as any;

    for (const [step, stepInfo] of Object.entries(this.stepsInfo)) {
      displaySteps.push({
        step: step,
        name: this.capitalize(step),
        state: stepInfo.state,
        display_state: this.getStepStateLabel(stepInfo.state),
        status_color: this.getStepStateColor(stepInfo.state),
        line_color: this.getStepStateLineColor(stepInfo.state),
        dot_icon: this.getStepStateDotIcon(stepInfo.state),
        debug: this.currentStep !== undefined && this.currentStep !== step,
        duration: this.formatDuration(stepInfo.duration),
        transparent_line: !this.allStepsSelected()
          ? this.currentStep && this.currentStep !== step
          : false,
      });
    }
    displaySteps = [...displaySteps];

    console.log(displaySteps);
    return displaySteps;
  }

  getStepStateDotIcon(state: stepState) {
    const icons: { [key in stepState]: string } = {
      [stepState.completed]: "completed.svg",
      [stepState.in_progress]: "in_progress.svg",
      [stepState.pending]: "pending.svg",
    };

    return icons[state];
  }

  getStepStateLineColor(state: stepState) {
    const colors: { [key in stepState]: string } = {
      [stepState.completed]: "#35BA55",
      [stepState.in_progress]: "#0036DA",
      [stepState.pending]: "#EBECED",
    };

    return colors[state];
  }

  getStepStateColor(state: stepState) {
    const colors: { [key in stepState]: string } = {
      [stepState.completed]: "#30A94F",
      [stepState.in_progress]: "#0036DA",
      [stepState.pending]: "#8E9397",
    };

    return colors[state];
  }

  getStepStateLabel(state: stepState) {
    switch (state) {
      case stepState.completed:
        return "Completed";
      case stepState.in_progress:
        return "In progress";
      case stepState.pending:
        return "Pending";
      default:
        return "";
    }
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDuration(duration: number) {
    //@TODO correct this formatting
    return `${duration} sec`;
  }

  isStepDisabled(step: string) {
    const state = (this.stepsInfo as any)[step].state;
    console.log(state);
    return state === stepState.pending || state === stepState.in_progress;
  }

  stepClicked(step: any) {
    console.log(step);
    if (this.isClickable && !this.isStepDisabled(step)) {
      this.currentStep = step;
      this.currentStepChange.emit(step);
    }
  }
}
