import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updatesCountryFilter'
})
export class updatesCountryFilterPipe implements PipeTransform {

  transform(updates: any [], selectedCountries: string []): any [] {
    console.log(selectedCountries)
    if (selectedCountries.length === 1 && selectedCountries[0] === "all"){
      return updates;
    }

    return updates.filter((update) => (update.data.some((source: any) => (source.country_code && selectedCountries.includes(source.country_code)))));
  }

}
